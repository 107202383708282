/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Customers20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M12.857 2.143a3.214 3.214 0 1 1 0 6.428m2.286 3.129a6.428 6.428 0 0 1 4.143 6v1.586h-2.143M10.357 5.357a3.214 3.214 0 1 1-6.428 0 3.214 3.214 0 0 1 6.428 0Zm3.214 13.929H.714v-1.429a6.429 6.429 0 1 1 12.857 0v1.429Z"
      />
    </svg>
  );
};

Customers20Icon.displayName = 'Customers20Icon';
