/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Support20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M7.857 7.857A2.143 2.143 0 1 1 10 10v1.428M19.286 10A9.286 9.286 0 1 1 .714 10a9.286 9.286 0 0 1 18.572 0ZM10 15a.357.357 0 1 1 0-.714.357.357 0 0 1 0 .714Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Support20Icon.displayName = 'Support20Icon';
