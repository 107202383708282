/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Send20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="m.714 4.286 8.372 7.143a1.429 1.429 0 0 0 1.828 0l8.372-7.143M2.143 2.5h15.714c.79 0 1.429.64 1.429 1.429V16.07c0 .79-.64 1.429-1.429 1.429H2.143c-.79 0-1.429-.64-1.429-1.429V3.93c0-.79.64-1.429 1.429-1.429Z"
      />
    </svg>
  );
};

Send20Icon.displayName = 'Send20Icon';
