/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Indicators20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M1.771 9.343 18.2 1.87m0 0L15.128.714M18.2 1.871l-1.143 3.072M18.929 19.3h-3.572v-10a.714.714 0 0 1 .715-.714h2.142a.714.714 0 0 1 .715.714v10Zm-7.143 0H8.213v-7.857a.715.715 0 0 1 .714-.714h2.143a.714.714 0 0 1 .715.714V19.3Zm-7.143 0H1.072v-5.714a.714.714 0 0 1 .714-.715h2.143a.714.714 0 0 1 .714.715V19.3Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Indicators20Icon.displayName = 'Indicators20Icon';
