/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Money16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.571 6.571h14.857m-4.571 4h1.714m-10.857-8h12.572c.63 0 1.142.512 1.142 1.143v8.572c0 .63-.511 1.143-1.142 1.143H1.714a1.143 1.143 0 0 1-1.143-1.143V3.714c0-.63.512-1.143 1.143-1.143Z"
      />
    </svg>
  );
};

Money16Icon.displayName = 'Money16Icon';
