/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Settings16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m5.977 2.571.492-1.268A1.143 1.143 0 0 1 7.532.57h.937a1.143 1.143 0 0 1 1.063.732l.491 1.268 1.669.96 1.348-.205a1.143 1.143 0 0 1 1.143.56l.457.8a1.143 1.143 0 0 1-.091 1.291l-.835 1.063v1.92l.858 1.063a1.142 1.142 0 0 1 .091 1.291l-.457.8a1.143 1.143 0 0 1-1.143.56l-1.349-.205-1.668.96-.492 1.268a1.143 1.143 0 0 1-1.062.732h-.96a1.143 1.143 0 0 1-1.063-.732l-.492-1.268-1.668-.96-1.349.205a1.144 1.144 0 0 1-1.143-.56l-.457-.8a1.143 1.143 0 0 1 .092-1.291l.834-1.063V7.04l-.857-1.063a1.143 1.143 0 0 1-.092-1.291l.457-.8a1.143 1.143 0 0 1 1.143-.56l1.349.205 1.691-.96ZM5.714 8a2.286 2.286 0 1 0 4.572 0 2.286 2.286 0 0 0-4.572 0Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Settings16Icon.displayName = 'Settings16Icon';
