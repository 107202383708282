/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Drag12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth=".9"
        d="m1.714 10.767 8.34-9.91m-4.36 10.286 5.308-6.306"
      />
    </svg>
  );
};

Drag12Icon.displayName = 'Drag12Icon';
