/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Adaptive20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M13.205 9.359H1.667m16.666-3.846H1.667m16.666-3.846H1.667m0 16.025v-3.846a.64.64 0 0 1 .64-.64h15.385a.64.64 0 0 1 .641.64v3.846a.641.641 0 0 1-.64.641H2.307a.641.641 0 0 1-.641-.64Z"
      />
    </svg>
  );
};

Adaptive20Icon.displayName = 'Adaptive20Icon';
