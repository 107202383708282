/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const EyeEyeballOpen16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.12 7.234a1.143 1.143 0 0 1 0 1.532c-1.2 1.291-3.932 3.805-7.12 3.805-3.189 0-5.92-2.514-7.12-3.805a1.143 1.143 0 0 1 0-1.532C2.08 5.943 4.81 3.43 8 3.43c3.188 0 5.92 2.514 7.12 3.805Z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 10.286a2.286 2.286 0 1 0 0-4.572 2.286 2.286 0 0 0 0 4.572Z"
      />
    </svg>
  );
};

EyeEyeballOpen16Icon.displayName = 'EyeEyeballOpen16Icon';
