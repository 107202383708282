/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const ResetColumns20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill={stroke}
      viewBox="0 0 20 20"
    >
      <path
        fill={stroke}
        fillRule="evenodd"
        d="M3 .45A2.55 2.55 0 0 1 5.55 3v14a2.55 2.55 0 0 1-5.1 0V3A2.55 2.55 0 0 1 3 .45ZM4.45 3a1.45 1.45 0 1 0-2.9 0v14a1.45 1.45 0 0 0 2.9 0V3Z"
        clipRule="evenodd"
      />
      <path
        fill={stroke}
        d="M10 1.55c.8 0 1.45.65 1.45 1.45v3a.55.55 0 0 0 1.1 0V3a2.55 2.55 0 0 0-5.1 0v6a.55.55 0 0 0 1.1 0V3c0-.8.65-1.45 1.45-1.45ZM18.45 3v5a.55.55 0 0 0 1.1 0V3a2.55 2.55 0 0 0-5.1 0v3a.55.55 0 0 0 1.1 0V3a1.45 1.45 0 0 1 2.9 0Zm-7.9 6a.55.55 0 1 0-1.1 0v3.708H13a.55.55 0 1 0 0-1.1h-1.873c.112-.12.237-.244.377-.368.68-.606 1.67-1.19 2.996-1.19 1.33 0 2.302.547 2.951 1.333.66.799.999 1.862.999 2.88 0 2.423-1.869 4.187-4.197 4.187a4.198 4.198 0 0 1-3.76-2.327.55.55 0 1 0-.985.49 5.298 5.298 0 0 0 4.745 2.937c2.915 0 5.297-2.236 5.297-5.287 0-1.245-.41-2.563-1.251-3.58-.85-1.03-2.13-1.733-3.799-1.733-1.675 0-2.916.745-3.728 1.468a7.69 7.69 0 0 0-.222.207V9Z"
      />

      <defs>
        <clipPath id="a">
          <path fill={stroke} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

ResetColumns20Icon.displayName = 'ResetColumns20Icon';
