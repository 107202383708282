/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const EmptyFile20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M10.714.714H3.571a1.429 1.429 0 0 0-1.428 1.429v15.714a1.429 1.429 0 0 0 1.428 1.429H16.43a1.429 1.429 0 0 0 1.428-1.429v-10M10.714.714l7.143 7.143M10.714.714v7.143h7.143"
      />
    </svg>
  );
};

EmptyFile20Icon.displayName = 'EmptyFile20Icon';
