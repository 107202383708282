/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Settings12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="m4.483 2.929.369-.952a.857.857 0 0 1 .797-.548h.703a.857.857 0 0 1 .797.548l.368.952 1.252.72 1.011-.155a.857.857 0 0 1 .857.42l.343.6a.856.856 0 0 1-.068.969l-.626.797v1.44l.643.797a.858.858 0 0 1 .068.969l-.343.6a.857.857 0 0 1-.857.42l-1.011-.155-1.252.72-.368.952a.857.857 0 0 1-.797.548h-.72a.857.857 0 0 1-.797-.548l-.369-.952-1.251-.72-1.012.155a.857.857 0 0 1-.857-.42l-.343-.6a.857.857 0 0 1 .069-.969l.625-.797V6.28l-.642-.797a.857.857 0 0 1-.069-.969l.343-.6a.857.857 0 0 1 .857-.42l1.011.155 1.269-.72ZM4.286 7a1.714 1.714 0 1 0 3.429 0 1.714 1.714 0 0 0-3.43 0Z"
      />
    </svg>
  );
};

Settings12Icon.displayName = 'Settings12Icon';
