/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Tasks20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M11.429 19.286H2.143a1.428 1.428 0 0 1-1.429-1.429V2.143A1.429 1.429 0 0 1 2.143.714h10.714M15 13.571h4.286M5 .714v18.572M15 5 17.143.714 19.286 5v12.143a2.143 2.143 0 1 1-4.286 0V5Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Tasks20Icon.displayName = 'Tasks20Icon';
