/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const OrderMain12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M9.8 12.572V1.428m0 0 2.8 3m-2.8-3-2.8 3m-2.8-3V12.57m0 0 2.8-3m-2.8 3-2.8-3"
      />
    </svg>
  );
};

OrderMain12Icon.displayName = 'OrderMain12Icon';
