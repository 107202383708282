/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Tutorial16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.143 2.286h1.714a.572.572 0 0 1 .572.571V12a.572.572 0 0 1-.572.571H1.143A.572.572 0 0 1 .572 12V2.857a.571.571 0 0 1 .571-.571h1.714m4 10.285L5.714 15.43m3.429-2.858 1.143 2.858m-5.714 0h6.857M5.895 8.687v-4.8a.446.446 0 0 1 .686-.388l4.114 2.4a.457.457 0 0 1 0 .788l-4.114 2.4a.457.457 0 0 1-.686-.4Z"
      />
    </svg>
  );
};

Tutorial16Icon.displayName = 'Tutorial16Icon';
