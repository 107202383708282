/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const HelpChat12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M4.714 3.857A1.286 1.286 0 1 1 6 5.143v.428m0 1.636v.027M10.714.429H1.286a.857.857 0 0 0-.857.857V8.57a.857.857 0 0 0 .857.858H3v2.142l2.674-2.14h5.04a.857.857 0 0 0 .858-.858V1.286a.857.857 0 0 0-.858-.857Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

HelpChat12Icon.displayName = 'HelpChat12Icon';
