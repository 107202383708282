/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const EmptyFolder16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.429 6.857a1.715 1.715 0 0 0-1.715-1.714H8L6.354 1.714H2.286A1.714 1.714 0 0 0 .57 3.43v9.142a1.714 1.714 0 0 0 1.715 1.715h11.428a1.714 1.714 0 0 0 1.715-1.715V6.857Z"
      />
    </svg>
  );
};

EmptyFolder16Icon.displayName = 'EmptyFolder16Icon';
