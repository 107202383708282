/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Tutorial20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M16.429 2.857h2.142a.714.714 0 0 1 .715.714V15a.714.714 0 0 1-.715.714H1.43A.714.714 0 0 1 .714 15V3.571a.714.714 0 0 1 .715-.714H3.57m5 12.857-1.428 3.572m4.286-3.572 1.428 3.572m-7.143 0h8.572m-6.917-8.427v-6a.557.557 0 0 1 .857-.485l5.143 3a.57.57 0 0 1 0 .985l-5.143 3a.571.571 0 0 1-.857-.5Z"
      />
    </svg>
  );
};

Tutorial20Icon.displayName = 'Tutorial20Icon';
