/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Applications12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M4.857 1.429H2.286a.857.857 0 0 0-.858.857v2.571c0 .474.384.857.858.857h2.571a.857.857 0 0 0 .857-.857V2.286a.857.857 0 0 0-.857-.857Zm6.857 0H9.143a.857.857 0 0 0-.857.857v2.571c0 .474.383.857.857.857h2.571a.857.857 0 0 0 .857-.857V2.286a.857.857 0 0 0-.857-.857ZM4.857 8.286H2.286a.857.857 0 0 0-.858.857v2.571c0 .474.384.857.858.857h2.571a.857.857 0 0 0 .857-.857V9.143a.857.857 0 0 0-.857-.857Zm6.857 0H9.143a.857.857 0 0 0-.857.857v2.571c0 .474.383.857.857.857h2.571a.857.857 0 0 0 .857-.857V9.143a.857.857 0 0 0-.857-.857Z"
      />
    </svg>
  );
};

Applications12Icon.displayName = 'Applications12Icon';
