/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Customers16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.286 1.714a2.571 2.571 0 1 1 0 5.143m1.828 2.503a5.143 5.143 0 0 1 3.315 4.8v1.268h-1.715M8.286 4.287a2.571 2.571 0 1 1-5.143 0 2.571 2.571 0 0 1 5.143 0Zm2.571 11.143H.571v-1.143a5.143 5.143 0 1 1 10.286 0v1.143Z"
      />
    </svg>
  );
};

Customers16Icon.displayName = 'Customers16Icon';
