/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Money20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M.714 8.214h18.572m-5.715 5h2.143m-13.571-10h15.714c.79 0 1.429.64 1.429 1.429v10.714c0 .79-.64 1.429-1.429 1.429H2.143c-.79 0-1.429-.64-1.429-1.429V4.643c0-.79.64-1.429 1.429-1.429Z"
      />
    </svg>
  );
};

Money20Icon.displayName = 'Money20Icon';
