/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Logout12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M8.143 9v1.714a.857.857 0 0 1-.857.857h-6a.857.857 0 0 1-.857-.857V1.286a.857.857 0 0 1 .857-.857h6a.857.857 0 0 1 .857.857V3M5.572 6h6m0 0L9.857 4.286M11.572 6 9.857 7.714"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Logout12Icon.displayName = 'Logout12Icon';
