import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const DiamondIcon: FC<SingleIconProps> = ({
  stroke = '#ffffff',
  width = 16,
  height = 15,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4284 5.97133L7.99986 13.9713M15.4284 5.97133L11.9999 1.3999M15.4284 5.97133H0.571289M7.99986 13.9713L0.571289 5.97133M7.99986 13.9713V5.97133M0.571289 5.97133L3.99986 1.3999M3.99986 1.3999H11.9999M3.99986 1.3999L7.99986 5.97133M11.9999 1.3999L7.99986 5.97133"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

DiamondIcon.displayName = 'DiamondIcon';
