/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Adaptive16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.564 7.487h-9.23M14.666 4.41H1.333m13.334-3.077H1.333m0 12.82v-3.076c0-.283.23-.513.513-.513h12.308c.283 0 .513.23.513.513v3.077c0 .283-.23.513-.513.513H1.846a.513.513 0 0 1-.513-.513Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Adaptive16Icon.displayName = 'Adaptive16Icon';
