/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Date20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M2.143 3.571A1.429 1.429 0 0 0 .714 5v12.857a1.428 1.428 0 0 0 1.429 1.429h15.714a1.428 1.428 0 0 0 1.429-1.429V5a1.429 1.429 0 0 0-1.429-1.429H15M.714 9.286h18.572M5 .714V6.43M15 .714V6.43M5 3.57h7.143"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Date20Icon.displayName = 'Date20Icon';
