/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Options20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M5 2.857a2.143 2.143 0 1 1-4.286 0 2.143 2.143 0 0 1 4.286 0Zm0 0h14.286M12.143 10a2.143 2.143 0 1 1-4.286 0m4.286 0a2.143 2.143 0 0 0-4.286 0m4.286 0h7.143M7.857 10H.714M15 17.143a2.143 2.143 0 1 0 4.286 0 2.143 2.143 0 0 0-4.286 0Zm0 0H.714"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Options20Icon.displayName = 'Options20Icon';
