/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const EyeEyeballHide12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M11.534 4.629c.326.291.6.574.806.797a.857.857 0 0 1 0 1.148C11.44 7.543 9.39 9.43 7 9.43h-.343m-2.34-.746a10.629 10.629 0 0 1-2.657-2.11.857.857 0 0 1 0-1.148C2.56 4.457 4.608 2.57 7 2.57c.942.02 1.865.277 2.683.746m2.031-2.031-9.428 9.428M5.79 7.21A1.714 1.714 0 0 1 5.286 6 1.714 1.714 0 0 1 7 4.286a1.72 1.72 0 0 1 1.209.505m.282 2.066a1.708 1.708 0 0 1-.634.626"
      />
    </svg>
  );
};

EyeEyeballHide12Icon.displayName = 'EyeEyeballHide12Icon';
