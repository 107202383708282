/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Still20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M8 8.571a.571.571 0 1 0 0-1.143.571.571 0 0 0 0 1.143Zm-3.429 0a.571.571 0 1 0 0-1.143.571.571 0 0 0 0 1.143Zm6.858 0a.571.571 0 1 0 0-1.142.571.571 0 0 0 0 1.142Z"
      />
    </svg>
  );
};

Still20Icon.displayName = 'Still20Icon';
