/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const DragNDrop16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={stroke}
      viewBox="0 0 16 16"
    >
      <path
        fill={stroke}
        d="M4.412 2.824a1.412 1.412 0 1 0 0-2.824 1.412 1.412 0 0 0 0 2.824Zm0 6.588a1.412 1.412 0 1 0 0-2.824 1.412 1.412 0 0 0 0 2.824ZM11 2.824A1.412 1.412 0 1 0 11 0a1.412 1.412 0 0 0 0 2.824Zm0 6.588a1.412 1.412 0 1 0 0-2.824 1.412 1.412 0 0 0 0 2.824ZM11 16a1.412 1.412 0 1 0 0-2.824A1.412 1.412 0 0 0 11 16Zm-6.588 0a1.412 1.412 0 1 0 0-2.824 1.412 1.412 0 0 0 0 2.824Z"
      />
    </svg>
  );
};

DragNDrop16Icon.displayName = 'DragNDrop16Icon';
