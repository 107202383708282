/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Tasks12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M7.857 12.571H2.286a.857.857 0 0 1-.858-.857V2.286a.857.857 0 0 1 .858-.857h6.428M10 9.143h2.571M4 1.429v11.143M10 4l1.286-2.571L12.57 4v7.286a1.286 1.286 0 1 1-2.571 0V4Z"
      />
    </svg>
  );
};

Tasks12Icon.displayName = 'Tasks12Icon';
