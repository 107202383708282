/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Warp12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M12.571 6H1.428m0 0 3-3m-3 3 3 3"
      />
    </svg>
  );
};

Warp12Icon.displayName = 'Warp12Icon';
