/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const OldDesign12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M6 .429s4.286 4.285 4.286 7.285A4.088 4.088 0 0 1 6 11.571M6 .43S1.715 4.714 1.715 7.714A4.088 4.088 0 0 0 6 11.571M6 .43v11.143m2.143-.515V2.95"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

OldDesign12Icon.displayName = 'OldDesign12Icon';
