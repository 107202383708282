/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const NewDesign12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="m11.572 5.229-5.572 6m5.572-6L9 1.8m2.572 3.429H.429m5.571 6-5.571-6m5.571 6v-6m-5.571 0L3 1.8m0 0h6m-6 0 3 3.429M9 1.8 6 5.229"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

NewDesign12Icon.displayName = 'NewDesign12Icon';
