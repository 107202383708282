/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Applications16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.143.571H1.714c-.631 0-1.143.512-1.143 1.143v3.429c0 .631.512 1.143 1.143 1.143h3.429c.63 0 1.143-.512 1.143-1.143V1.714c0-.63-.512-1.143-1.143-1.143Zm9.143 0h-3.429c-.631 0-1.143.512-1.143 1.143v3.429c0 .631.512 1.143 1.143 1.143h3.429c.63 0 1.143-.512 1.143-1.143V1.714c0-.63-.512-1.143-1.143-1.143ZM5.143 9.714H1.714c-.631 0-1.143.512-1.143 1.143v3.429c0 .63.512 1.143 1.143 1.143h3.429c.63 0 1.143-.512 1.143-1.143v-3.429c0-.631-.512-1.143-1.143-1.143Zm9.143 0h-3.429c-.631 0-1.143.512-1.143 1.143v3.429c0 .63.512 1.143 1.143 1.143h3.429c.63 0 1.143-.512 1.143-1.143v-3.429c0-.631-.512-1.143-1.143-1.143Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Applications16Icon.displayName = 'Applications16Icon';
