/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Print16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.286 10.857a1.143 1.143 0 0 0-1.143-1.143H2.857a1.143 1.143 0 0 0-1.143 1.143m12.572 0v4.572H1.714v-4.572m12.572 0a1.143 1.143 0 0 0 1.143-1.143V7.43a1.143 1.143 0 0 0-1.143-1.143H1.714A1.143 1.143 0 0 0 .572 7.429v2.285a1.143 1.143 0 0 0 1.142 1.143m2.858 1.714h6.857m-8-12h9.142v5.715H3.428V.57Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Print16Icon.displayName = 'Print16Icon';
