/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const EyeEyeballHide20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M17.557 7.714c.542.486 1 .957 1.342 1.329a1.428 1.428 0 0 1 0 1.914c-1.5 1.614-4.914 4.757-8.9 4.757h-.571m-3.9-1.243A17.714 17.714 0 0 1 1.1 10.957a1.429 1.429 0 0 1 0-1.914C2.6 7.429 6.014 4.286 10 4.286a9.371 9.371 0 0 1 4.471 1.243m3.386-3.386L2.143 17.857m5.843-5.843A2.857 2.857 0 0 1 7.143 10 2.857 2.857 0 0 1 10 7.143c.757.003 1.48.306 2.014.843m.472 3.443a2.857 2.857 0 0 1-1.058 1.042"
      />
    </svg>
  );
};

EyeEyeballHide20Icon.displayName = 'EyeEyeballHide20Icon';
