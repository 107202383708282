/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const AlertWarningTriangle16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 5.714v3.429m.571 3.428a.571.571 0 1 1-1.142 0 .571.571 0 0 1 1.142 0ZM9.017 1.2a1.143 1.143 0 0 0-2.034 0L.697 13.77a1.143 1.143 0 0 0 1.017 1.657h12.572a1.143 1.143 0 0 0 1.017-1.657L9.017 1.2Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

AlertWarningTriangle16Icon.displayName = 'AlertWarningTriangle16Icon';
