/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Date12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M2.286 3.143A.857.857 0 0 0 1.429 4v7.714a.857.857 0 0 0 .857.857h9.428a.857.857 0 0 0 .858-.857V4a.857.857 0 0 0-.858-.857H10M1.43 6.57h11.143M4 1.43v3.428m6-3.428v3.428M4 3.143h4.286"
      />
    </svg>
  );
};

Date12Icon.displayName = 'Date12Icon';
