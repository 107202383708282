/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Sales16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.143 5.143V3.429a2.857 2.857 0 0 1 5.714 0v1.714m3.863 9.017a1.144 1.144 0 0 1-.674 1.172 1.142 1.142 0 0 1-.469.096H2.423A1.142 1.142 0 0 1 1.28 14.16l1.006-9.017h11.428l1.006 9.017Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Sales16Icon.displayName = 'Sales16Icon';
