/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Notifications20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M7.857 17.614a2.214 2.214 0 0 0 4.286 0M10 .714a6.129 6.129 0 0 1 6.129 6.129c0 6.814 2.485 8.157 3.157 8.157H.714c.686 0 3.157-1.357 3.157-8.157A6.129 6.129 0 0 1 10 .714Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Notifications20Icon.displayName = 'Notifications20Icon';
