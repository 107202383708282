/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Delete12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 13"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M.857 3.5h10.286m-7.286 0v-.429a2.143 2.143 0 1 1 4.286 0V3.5M4.714 5.214V9.93m2.572-4.715V9.93M2.143 3.5h7.714v7.714a.857.857 0 0 1-.857.857H3a.857.857 0 0 1-.857-.857V3.5Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" transform="translate(0 .5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

Delete12Icon.displayName = 'Delete12Icon';
