/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Locked16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6.286V4.57a4 4 0 0 0-8 0v1.715m-.571 0h9.143c.63 0 1.142.511 1.142 1.143v6.857c0 .63-.511 1.143-1.143 1.143H3.43a1.143 1.143 0 0 1-1.143-1.143V7.429c0-.632.511-1.143 1.143-1.143Zm5.142 4.571a.571.571 0 1 1-1.142 0 .571.571 0 0 1 1.142 0Z"
      />
    </svg>
  );
};

Locked16Icon.displayName = 'Locked16Icon';
