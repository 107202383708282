/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Tutorial12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M9.857 1.714h1.286a.429.429 0 0 1 .428.429V9a.428.428 0 0 1-.428.429H.857A.429.429 0 0 1 .428 9V2.143a.429.429 0 0 1 .429-.429h1.286m3 7.715-.857 2.142M6.857 9.43l.857 2.142m-4.286 0h5.143m-4.15-5.055v-3.6a.334.334 0 0 1 .515-.292l3.085 1.8a.343.343 0 0 1 0 .592l-3.085 1.8a.343.343 0 0 1-.515-.3Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Tutorial12Icon.displayName = 'Tutorial12Icon';
