/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Money12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M1.428 4.929h11.143m-3.428 3h1.285m-8.142-6h9.428c.474 0 .857.383.857.857v6.428a.857.857 0 0 1-.857.857H2.286a.857.857 0 0 1-.858-.857V2.786c0-.474.384-.857.858-.857Z"
      />
    </svg>
  );
};

Money12Icon.displayName = 'Money12Icon';
