/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Still16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 10.714a.714.714 0 1 0 0-1.428.714.714 0 0 0 0 1.428Zm-4.286 0a.714.714 0 1 0 0-1.428.714.714 0 0 0 0 1.428Zm8.572 0a.714.714 0 1 0 0-1.428.714.714 0 0 0 0 1.428Z"
      />
    </svg>
  );
};

Still16Icon.displayName = 'Still16Icon';
