/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Retail20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M2.143 12.143v6.429a.714.714 0 0 0 .714.714h14.286a.714.714 0 0 0 .714-.714v-6.429M.714 5v2.857a.714.714 0 0 0 .715.715H18.57a.714.714 0 0 0 .715-.715V5M.714 5l1.743-3.5A1.429 1.429 0 0 1 3.743.714h12.514a1.429 1.429 0 0 1 1.286.786L19.286 5M.714 5h18.572m-7.857 7.143v7.143"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Retail20Icon.displayName = 'Retail20Icon';
