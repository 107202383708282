/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const ClearFilter20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="1.1"
        d="m8.929 18.571-1.072.715V9.757A9.3 9.3 0 0 1 .714.714h18.572a9.3 9.3 0 0 1-2.787 6.635M10 16.369A4.748 4.748 0 0 0 14.253 19C16.875 19 19 17 19 14.263 19 12 17.5 9.5 14.5 9.5s-4.466 2.658-4.5 2.658m0 0V9m0 3.158h3"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

ClearFilter20Icon.displayName = 'ClearFilter20Icon';
