/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Customers12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 13"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M8.714 1.286a1.929 1.929 0 0 1 0 3.857m1.372 1.877a3.857 3.857 0 0 1 2.485 3.6v.952h-1.285M7.214 3.213a1.929 1.929 0 1 1-3.857 0 1.929 1.929 0 0 1 3.857 0Zm1.929 8.357H1.428v-.857a3.857 3.857 0 1 1 7.715 0v.857Z"
      />
    </svg>
  );
};

Customers12Icon.displayName = 'Customers12Icon';
