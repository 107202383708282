/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Comment16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.143 14.286.572 15.429 1.714 12V1.714A1.143 1.143 0 0 1 2.857.571h11.429a1.143 1.143 0 0 1 1.143 1.143v11.429a1.143 1.143 0 0 1-1.143 1.143H5.143Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Comment16Icon.displayName = 'Comment16Icon';
