/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Retail16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.714 9.714v5.143a.572.572 0 0 0 .572.572h11.428a.571.571 0 0 0 .572-.572V9.714M.57 4v2.286a.571.571 0 0 0 .572.571h13.714a.572.572 0 0 0 .571-.571V4M.571 4l1.395-2.8A1.143 1.143 0 0 1 2.994.571h10.012a1.143 1.143 0 0 1 1.028.629L15.428 4M.571 4h14.857M9.143 9.714v5.715"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Retail16Icon.displayName = 'Retail16Icon';
