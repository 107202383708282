import React, { FC, SyntheticEvent, useCallback } from 'react';

import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';
import { BodyText } from '@lognex/ui-typography';
import { Icon, IconAlias } from '@lognex/ui-icon';

const SItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 6px 24px;
  cursor: pointer;

  color: #5f6d79;

  svg path {
    stroke: #5f6d79;
  }

  &:not(:last-of-type) {
    border-right: 1px solid #cbd0db;
  }
`;

const cssSelected = css`
  background: #dff0fe;
  color: #091739;

  svg path {
    stroke: #091739;
  }
`;

export interface SwitchItem {
  iconPrepend?: `${IconAlias}`;
  text: string;
  value: string | number | boolean | null;
}

export interface ItemProps {
  index: number;
  selected?: boolean;
  item: SwitchItem;
  onSelect: (i: number) => void;
}

export const Item: FC<ItemProps> = ({ index, selected, onSelect, item }) => {
  const handleSelect = useCallback(() => {
    onSelect(index);
  }, []);

  return (
    <SItem className={cx(selected && cssSelected)} onClick={handleSelect}>
      {item.iconPrepend && <Icon alias={item.iconPrepend} />}{' '}
      <BodyText>{item.text}</BodyText>
    </SItem>
  );
};

Item.displayName = 'SwitchItem';
