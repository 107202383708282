/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const EyeEyeballOpen12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M12.34 5.426a.857.857 0 0 1 0 1.148C11.44 7.543 9.39 9.43 7 9.43c-2.392 0-4.44-1.886-5.34-2.855a.857.857 0 0 1 0-1.148C2.56 4.457 4.608 2.57 7 2.57c2.391 0 4.44 1.886 5.34 2.855Z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M7 7.714a1.714 1.714 0 1 0 0-3.428 1.714 1.714 0 0 0 0 3.428Z"
      />
    </svg>
  );
};

EyeEyeballOpen12Icon.displayName = 'EyeEyeballOpen12Icon';
