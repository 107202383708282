import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const PlusIcon: FC<SingleIconProps> = ({
  stroke = '#ffffff',
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.5V18.5M1.5 9.95077H18.5"
        stroke={stroke}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

PlusIcon.displayName = 'PlusIcon';
