/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Create12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M7 4.429V9.57M4.428 7h5.143m3 0A5.571 5.571 0 1 1 1.43 7a5.571 5.571 0 0 1 11.14 0Z"
      />
    </svg>
  );
};

Create12Icon.displayName = 'Create12Icon';
