/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const HeightExpand16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 13.143V2.857m0 10.286 2.857-2.857M8 13.143l-2.857-2.857M8 2.857l2.857 2.857M8 2.857 5.143 5.714M15.429.571H.572M15.429 15.43H.572"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

HeightExpand16Icon.displayName = 'HeightExpand16Icon';
