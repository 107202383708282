/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Re12Icon: FC<SingleIconProps> = ({ stroke = 'currentColor' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="m9.429 7.714 1.714-.428m0 0L11.572 9m-.429-1.714A5.795 5.795 0 0 1 6 11.143a5.143 5.143 0 0 1-4.834-3.386m1.406-3.471-1.715.428m0 0L.43 3m.428 1.714C1.577 2.743 3.79.857 6 .857a5.143 5.143 0 0 1 4.834 3.429"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Re12Icon.displayName = 'Re12Icon';
