/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Re20Icon: FC<SingleIconProps> = ({ stroke = 'currentColor' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="m15.714 12.857 2.857-.714m0 0L19.286 15m-.715-2.857A9.658 9.658 0 0 1 10 18.57a8.57 8.57 0 0 1-8.057-5.642m2.343-5.786-2.857.714m0 0L.714 5m.715 2.857C2.629 4.571 6.314 1.43 10 1.43a8.572 8.572 0 0 1 8.057 5.714"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Re20Icon.displayName = 'Re20Icon';
