/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const DocumetArrow12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M2.143 6.429V1.286A.857.857 0 0 1 3 .429h4.286m0 0 4.285 4.285M7.286.43v4.285h4.285m0 0v6a.857.857 0 0 1-.857.857H5.571m-2.959 0 1.714-1.714m0 0L2.612 8.143m1.714 1.714H.47"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

DocumetArrow12Icon.displayName = 'DocumetArrow12Icon';
