/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Purchases12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M4.428 11.286h6.523c.225 0 .44.09.6.248l1.02 1.037m-8.143-1.285a1.491 1.491 0 1 0-2.975-.214 1.491 1.491 0 0 0 2.975.214Zm-3-9.857h.858a.857.857 0 0 1 .857.857V9.57m3.428-6h3.857c.474 0 .858.384.858.858v3.857a.857.857 0 0 1-.857.857H6.57a.857.857 0 0 1-.857-.857V4.429c0-.474.384-.858.857-.858Z"
      />
    </svg>
  );
};

Purchases12Icon.displayName = 'Purchases12Icon';
