/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Print20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M17.857 13.571a1.429 1.429 0 0 0-1.428-1.428H3.57a1.429 1.429 0 0 0-1.428 1.428m15.714 0v5.715H2.143V13.57m15.714 0a1.429 1.429 0 0 0 1.429-1.428V9.286a1.429 1.429 0 0 0-1.429-1.429H2.143A1.429 1.429 0 0 0 .714 9.286v2.857a1.429 1.429 0 0 0 1.429 1.428m3.571 2.143h8.572m-10-15h11.428v7.143H4.286V.714Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Print20Icon.displayName = 'Print20Icon';
