/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Logout20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M13.571 15v2.857a1.429 1.429 0 0 1-1.428 1.429h-10a1.429 1.429 0 0 1-1.429-1.429V2.143A1.429 1.429 0 0 1 2.143.714h10a1.429 1.429 0 0 1 1.428 1.429V5m-4.285 5h10m0 0-2.857-2.857M19.286 10l-2.857 2.857"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Logout20Icon.displayName = 'Logout20Icon';
