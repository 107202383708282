/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Purchases20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M5.714 17.143h10.872c.375.001.734.15 1 .414l1.7 1.729M5.714 17.143a2.486 2.486 0 1 0-4.958-.357 2.486 2.486 0 0 0 4.958.357ZM.714.714h1.429a1.429 1.429 0 0 1 1.429 1.429v12.143m5.714-10h6.428c.79 0 1.429.64 1.429 1.428v6.429c0 .789-.64 1.428-1.429 1.428H9.286c-.79 0-1.43-.64-1.43-1.428V5.714c0-.789.64-1.428 1.43-1.428Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Purchases20Icon.displayName = 'Purchases20Icon';
