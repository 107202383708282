/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Products12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M12.572 1.429h-2.143l-.746 7.414a.857.857 0 0 1-.857.728h-5.4a.857.857 0 0 1-.857-.582L1.429 5.56a.857.857 0 0 1 .12-.771.857.857 0 0 1 .737-.36h7.843M4 12.143a.429.429 0 1 1-.857 0 .429.429 0 0 1 .857 0Zm5.571 0a.429.429 0 1 1-.857 0 .429.429 0 0 1 .857 0Z"
      />
    </svg>
  );
};

Products12Icon.displayName = 'Products12Icon';
