/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Bullet12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill={stroke}
      viewBox="0 0 12 12"
    >
      <path fill={stroke} d="M8 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
    </svg>
  );
};

Bullet12Icon.displayName = 'Bullet12Icon';
