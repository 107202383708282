/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const OrderAsc12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 13 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M5.8.857H1.43M7.6 3.43H1.43M9.143 6H1.429M12.4 11.143H1.43M11.2 8.57H1.43"
      />
    </svg>
  );
};

OrderAsc12Icon.displayName = 'OrderAsc12Icon';
