/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const ColumnOptions20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill={stroke}
      viewBox="0 0 20 20"
    >
      <path
        fill={stroke}
        fillRule="evenodd"
        d="M3 .45A2.55 2.55 0 0 1 5.55 3v14a2.55 2.55 0 0 1-5.1 0V3A2.55 2.55 0 0 1 3 .45ZM4.45 3a1.45 1.45 0 1 0-2.9 0v14a1.45 1.45 0 0 0 2.9 0V3Z"
        clipRule="evenodd"
      />
      <path
        fill={stroke}
        d="M10 1.55c.8 0 1.45.65 1.45 1.45v3a.55.55 0 0 0 1.1 0V3a2.55 2.55 0 0 0-5.1 0v6a.55.55 0 0 0 1.1 0V3c0-.8.65-1.45 1.45-1.45ZM18.45 3v5a.55.55 0 0 0 1.1 0V3a2.55 2.55 0 0 0-5.1 0v3a.55.55 0 0 0 1.1 0V3a1.45 1.45 0 0 1 2.9 0Z"
      />
      <path
        fill={stroke}
        fillRule="evenodd"
        d="M14 11.736a2.264 2.264 0 1 0 0 4.528 2.264 2.264 0 0 0 0-4.528Zm-.823 1.44a1.164 1.164 0 1 1 1.646 1.647 1.164 1.164 0 0 1-1.646-1.646Z"
        clipRule="evenodd"
      />
      <path
        fill={stroke}
        fillRule="evenodd"
        d="M13.649 7.879h-.002a1.407 1.407 0 0 0-1.308.9l-.298.768-.932.53-.823-.126-.009-.002a1.407 1.407 0 0 0-1.407.69l-.002.003-.341.597a1.407 1.407 0 0 0 .114 1.587l.002.002.521.646v1.056l-.507.646a1.407 1.407 0 0 0-.113 1.585l.341.598.002.002a1.408 1.408 0 0 0 1.407.69l.01-.001.82-.126.916.527.299.77a1.408 1.408 0 0 0 1.308.9h.723a1.408 1.408 0 0 0 1.309-.9l.298-.77.916-.527.821.126h.01a1.407 1.407 0 0 0 1.407-.689l.001-.002.341-.598a1.407 1.407 0 0 0-.114-1.586l-.523-.649V13.47l.507-.646a1.408 1.408 0 0 0 .113-1.585l-.341-.598-.002-.002a1.406 1.406 0 0 0-1.407-.69l-.009.002-.82.125-.917-.527-.298-.77a1.408 1.408 0 0 0-1.309-.9h-.704Zm0 1.1h.702a.307.307 0 0 1 .285.196l.368.952a.55.55 0 0 0 .239.278l1.251.72a.55.55 0 0 0 .358.067l1.006-.153a.307.307 0 0 1 .303.15l.342.598.003.006a.307.307 0 0 1-.025.347l-.002.003-.626.797a.55.55 0 0 0-.117.34v1.44a.55.55 0 0 0 .122.345l.64.795.001.001a.307.307 0 0 1 .024.346l-.003.006-.342.597v.001a.307.307 0 0 1-.303.15l-1.006-.153a.55.55 0 0 0-.357.067l-1.252.72a.55.55 0 0 0-.238.278l-.369.951a.31.31 0 0 1-.285.198h-.718a.308.308 0 0 1-.285-.197l-.37-.952a.55.55 0 0 0-.238-.278l-1.251-.72a.55.55 0 0 0-.357-.067l-1.006.153a.307.307 0 0 1-.303-.15h-.001l-.341-.598-.004-.006a.307.307 0 0 1 .025-.347l.002-.003.626-.797a.55.55 0 0 0 .117-.34v-1.44a.55.55 0 0 0-.121-.345l-.642-.796a.307.307 0 0 1-.024-.346l.004-.006.341-.598a.307.307 0 0 1 .304-.15l1.006.153a.55.55 0 0 0 .354-.065l1.268-.72a.55.55 0 0 0 .242-.28l.368-.951a.31.31 0 0 1 .286-.197Z"
        clipRule="evenodd"
      />

      <defs>
        <clipPath id="a">
          <path fill={stroke} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

ColumnOptions20Icon.displayName = 'ColumnOptions20Icon';
