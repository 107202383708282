/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const EyeEyeballOpen20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M18.9 9.043a1.429 1.429 0 0 1 0 1.914c-1.5 1.614-4.914 4.757-8.9 4.757-3.986 0-7.4-3.143-8.9-4.757a1.428 1.428 0 0 1 0-1.914C2.6 7.429 6.014 4.286 10 4.286c3.986 0 7.4 3.143 8.9 4.757Z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M10 12.857a2.857 2.857 0 1 0 0-5.714 2.857 2.857 0 0 0 0 5.714Z"
      />
    </svg>
  );
};

EyeEyeballOpen20Icon.displayName = 'EyeEyeballOpen20Icon';
