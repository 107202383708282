/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Guide20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M17.857 17.857a1.429 1.429 0 0 1-1.428 1.429H3.57a1.429 1.429 0 0 1-1.428-1.429V2.143A1.429 1.429 0 0 1 3.57.714h9.286l5 5v12.143Z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M11.429 10.714 8.57 7.857l-2.857 2.857v-10h5.715v10Z"
      />
    </svg>
  );
};

Guide20Icon.displayName = 'Guide20Icon';
