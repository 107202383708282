import React, { FC, useCallback, useState } from 'react';
import { styled } from '@linaria/react';
import { Item, SwitchItem } from './Item';

const SWrapper = styled.div`
  display: flex;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid #cbd0db;
  overflow: hidden;
`;

export interface SwitchProps {
  defaultValue?: SwitchItem['value'];
  items: SwitchItem[];
  onChange?: (value: SwitchItem['value']) => void;
}

export const Switch: FC<SwitchProps> = ({
  defaultValue = null,
  items,
  onChange,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(
    items.findIndex((e) => e.value === defaultValue)
  );
  const handleSelect = useCallback(
    (index: number) => {
      setSelectedIndex(index);

      if (onChange) {
        onChange(items[index].value);
      }
    },
    [onChange]
  );

  return (
    <SWrapper>
      {items.map((item, i) => (
        <Item
          key={`${item.text}${i}`}
          item={item}
          index={i}
          selected={selectedIndex === i}
          onSelect={handleSelect}
        />
      ))}
    </SWrapper>
  );
};

Switch.displayName = 'Switch';
