/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const NewDesign20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="m19.286 8.714-9.286 10m9.286-10L15 3m4.286 5.714H.714m9.286 10-9.286-10m9.286 10v-10m-9.286 0L5 3m0 0h10M5 3l5 5.714M15 3l-5 5.714"
      />
    </svg>
  );
};

NewDesign20Icon.displayName = 'NewDesign20Icon';
