/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const User16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.18 13.53a5.643 5.643 0 0 1 9.64 0m-1.998-7.223a2.822 2.822 0 1 1-5.644 0 2.822 2.822 0 0 1 5.644 0ZM15.335 8A7.336 7.336 0 1 1 .664 8a7.336 7.336 0 0 1 14.672 0Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

User16Icon.displayName = 'User16Icon';
