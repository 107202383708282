/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const DocumetArrow20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M3.571 10.714V2.143A1.429 1.429 0 0 1 5 .714h7.143m0 0 7.143 7.143M12.143.714v7.143h7.143m0 0v10a1.429 1.429 0 0 1-1.429 1.429H9.286m-4.932 0 2.857-2.857m0 0L4.354 13.57m2.856 2.86H.782"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

DocumetArrow20Icon.displayName = 'DocumetArrow20Icon';
