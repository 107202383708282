/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Send12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="m1.429 2.571 5.023 4.286a.857.857 0 0 0 1.097 0l5.023-4.286M2.286 1.5h9.428c.474 0 .858.384.858.857v7.286a.857.857 0 0 1-.858.857H2.286a.857.857 0 0 1-.857-.857V2.357c0-.473.383-.857.857-.857Z"
      />
    </svg>
  );
};

Send12Icon.displayName = 'Send12Icon';
