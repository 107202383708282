import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const RemoveCloseIcon: FC<SingleIconProps> = ({
  stroke = '#ffffff',
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2857 0.714355L0.714233 19.2858M0.714233 0.714355L19.2857 19.2858"
        stroke={stroke}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

RemoveCloseIcon.displayName = 'RemoveCloseIcon';
