/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const User12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M2.386 10.148a4.232 4.232 0 0 1 7.228 0M8.116 4.73a2.116 2.116 0 1 1-4.232 0 2.116 2.116 0 0 1 4.232 0ZM11.502 6A5.502 5.502 0 1 1 .498 6a5.502 5.502 0 0 1 11.004 0Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

User12Icon.displayName = 'User12Icon';
