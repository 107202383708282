/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const EyeEyeballHide16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.045 6.171c.434.389.8.766 1.075 1.063a1.143 1.143 0 0 1 0 1.532c-1.2 1.291-3.932 3.805-7.12 3.805h-.458m-3.12-.994A14.17 14.17 0 0 1 .88 8.766a1.143 1.143 0 0 1 0-1.532C2.08 5.943 4.81 3.43 8 3.43a7.497 7.497 0 0 1 3.577.994m2.709-2.709L1.714 14.286M6.39 9.61A2.286 2.286 0 0 1 5.714 8 2.286 2.286 0 0 1 8 5.714a2.286 2.286 0 0 1 1.611.675m.378 2.754a2.286 2.286 0 0 1-.846.834"
      />
    </svg>
  );
};

EyeEyeballHide16Icon.displayName = 'EyeEyeballHide16Icon';
