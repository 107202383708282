/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Support12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M5.714 5.714A1.286 1.286 0 1 1 7 7v.857M12.571 7A5.571 5.571 0 1 1 1.43 7a5.571 5.571 0 0 1 11.14 0ZM7 10a.214.214 0 1 1 0-.429A.214.214 0 0 1 7 10Z"
      />
    </svg>
  );
};

Support12Icon.displayName = 'Support12Icon';
