/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Delete20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M1.429 5H18.57M6.43 5v-.714a3.571 3.571 0 1 1 7.142 0V5M7.857 7.857v7.857m4.286-7.857v7.857M3.57 5h12.86v12.857A1.429 1.429 0 0 1 15 19.286H5a1.429 1.429 0 0 1-1.429-1.429V5Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Delete20Icon.displayName = 'Delete20Icon';
