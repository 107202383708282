/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Send16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m.572 3.429 6.697 5.714a1.143 1.143 0 0 0 1.463 0l6.697-5.714M1.714 2h12.572c.631 0 1.143.512 1.143 1.143v9.714c0 .631-.512 1.143-1.143 1.143H1.714a1.143 1.143 0 0 1-1.142-1.143V3.143C.572 2.512 1.083 2 1.714 2Z"
      />
    </svg>
  );
};

Send16Icon.displayName = 'Send16Icon';
