/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const OrderDesc12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M12.572.857H1.429m9.428 2.572H1.43M9.143 6H1.429m4.285 5.143H1.43m6-2.572h-6"
      />
    </svg>
  );
};

OrderDesc12Icon.displayName = 'OrderDesc12Icon';
