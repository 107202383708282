/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Tasks16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.143 15.429H1.714a1.143 1.143 0 0 1-1.143-1.143V1.714A1.143 1.143 0 0 1 1.714.571h8.572M12 10.857h3.429M4 .571V15.43M12 4 13.714.571 15.43 4v9.714a1.714 1.714 0 0 1-3.429 0V4Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Tasks16Icon.displayName = 'Tasks16Icon';
