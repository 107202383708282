/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Locked20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M15 7.857V5.714a5 5 0 0 0-10 0v2.143m-.714 0h11.428c.79 0 1.429.64 1.429 1.429v8.571c0 .789-.64 1.428-1.429 1.428H4.286c-.79 0-1.429-.64-1.429-1.428V9.286c0-.79.64-1.429 1.429-1.429Zm6.428 5.714a.714.714 0 1 1-1.428 0 .714.714 0 0 1 1.428 0Z"
      />
    </svg>
  );
};

Locked20Icon.displayName = 'Locked20Icon';
