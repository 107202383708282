/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Indicators16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.417 7.474 14.56 1.497m0 0L12.103.571m2.457.926-.914 2.457m1.497 11.486h-2.857v-8a.571.571 0 0 1 .571-.571h1.715a.572.572 0 0 1 .571.571v8Zm-5.714 0H6.572V9.154a.571.571 0 0 1 .571-.571h1.714a.571.571 0 0 1 .572.571v6.286Zm-5.715 0H.857v-4.572a.571.571 0 0 1 .572-.57h1.714a.571.571 0 0 1 .571.57v4.572Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Indicators16Icon.displayName = 'Indicators16Icon';
