/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Archive20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M2.143 7.914h15.714M2.143 3.63h15.714m1.429 10a1.429 1.429 0 0 0-1.429-1.429h-4.286v1.429H6.43V12.2H2.143a1.429 1.429 0 0 0-1.429 1.429v4.285a1.428 1.428 0 0 0 1.429 1.429h15.714a1.429 1.429 0 0 0 1.429-1.429V13.63Z"
      />
    </svg>
  );
};

Archive20Icon.displayName = 'Archive20Icon';
