/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Locked12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 13"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M9 5.214V3.93a3 3 0 1 0-6 0v1.285m-.429 0H9.43c.473 0 .857.384.857.857v5.143a.857.857 0 0 1-.857.857H2.572a.857.857 0 0 1-.858-.857V6.071c0-.473.384-.857.857-.857ZM6.43 8.643a.429.429 0 1 1-.858 0 .429.429 0 0 1 .858 0Z"
      />
    </svg>
  );
};

Locked12Icon.displayName = 'Locked12Icon';
