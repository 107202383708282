/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const OldDesign20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M10 .714s7.143 7.143 7.143 12.143A6.814 6.814 0 0 1 10 19.286M10 .714S2.859 7.857 2.859 12.857A6.814 6.814 0 0 0 10 19.286M10 .714v18.572m3.571-.857V4.914"
      />
    </svg>
  );
};

OldDesign20Icon.displayName = 'OldDesign20Icon';
