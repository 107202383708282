/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Products16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.428.571h-2.857l-.994 9.886a1.143 1.143 0 0 1-1.143.972h-7.2a1.143 1.143 0 0 1-1.143-.778L.571 6.08a1.143 1.143 0 0 1 .16-1.029 1.143 1.143 0 0 1 .983-.48h10.457M4 14.857a.571.571 0 1 1-1.143 0 .571.571 0 0 1 1.143 0Zm7.428 0a.571.571 0 1 1-1.142 0 .571.571 0 0 1 1.143 0Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Products16Icon.displayName = 'Products16Icon';
