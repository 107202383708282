/**
    THIS FILE IS AUTOGENERATED
  */

import { Adaptive12Icon } from './Adaptive12Icon';
import { Adaptive16Icon } from './Adaptive16Icon';
import { Adaptive20Icon } from './Adaptive20Icon';
import { Add12Icon } from './Add12Icon';
import { Add16Icon } from './Add16Icon';
import { Add20Icon } from './Add20Icon';
import { AlertWarningTriangle16Icon } from './AlertWarningTriangle16Icon';
import { Alphabet20Icon } from './Alphabet20Icon';
import { Applications12Icon } from './Applications12Icon';
import { Applications16Icon } from './Applications16Icon';
import { Applications20Icon } from './Applications20Icon';
import { Archive12Icon } from './Archive12Icon';
import { Archive16Icon } from './Archive16Icon';
import { Archive20Icon } from './Archive20Icon';
import { Bullet12Icon } from './Bullet12Icon';
import { Bullet16Icon } from './Bullet16Icon';
import { Check16Icon } from './Check16Icon';
import { ClearFilter20Icon } from './ClearFilter20Icon';
import { Clear12Icon } from './Clear12Icon';
import { ClipboardTextList16Icon } from './ClipboardTextList16Icon';
import { ClipboardTextList20Icon } from './ClipboardTextList20Icon';
import { ColumnOptions20Icon } from './ColumnOptions20Icon';
import { Comment12Icon } from './Comment12Icon';
import { Comment16Icon } from './Comment16Icon';
import { Comment20Icon } from './Comment20Icon';
import { Create12Icon } from './Create12Icon';
import { Create16Icon } from './Create16Icon';
import { Create20Icon } from './Create20Icon';
import { Customers12Icon } from './Customers12Icon';
import { Customers16Icon } from './Customers16Icon';
import { Customers20Icon } from './Customers20Icon';
import { Date12Icon } from './Date12Icon';
import { Date16Icon } from './Date16Icon';
import { Date20Icon } from './Date20Icon';
import { Delete12Icon } from './Delete12Icon';
import { Delete16Icon } from './Delete16Icon';
import { Delete20Icon } from './Delete20Icon';
import { DocumetArrow12Icon } from './DocumetArrow12Icon';
import { DocumetArrow16Icon } from './DocumetArrow16Icon';
import { DocumetArrow20Icon } from './DocumetArrow20Icon';
import { Down12Icon } from './Down12Icon';
import { Down16Icon } from './Down16Icon';
import { Down20Icon } from './Down20Icon';
import { Drag12Icon } from './Drag12Icon';
import { DragNDrop12Icon } from './DragNDrop12Icon';
import { DragNDrop16Icon } from './DragNDrop16Icon';
import { DragNDrop20Icon } from './DragNDrop20Icon';
import { Edit16Icon } from './Edit16Icon';
import { EmptyFile16Icon } from './EmptyFile16Icon';
import { EmptyFile20Icon } from './EmptyFile20Icon';
import { EmptyFolder16Icon } from './EmptyFolder16Icon';
import { EmptyFolder20Icon } from './EmptyFolder20Icon';
import { EyeEyeballHide12Icon } from './EyeEyeballHide12Icon';
import { EyeEyeballHide16Icon } from './EyeEyeballHide16Icon';
import { EyeEyeballHide20Icon } from './EyeEyeballHide20Icon';
import { EyeEyeballOpen12Icon } from './EyeEyeballOpen12Icon';
import { EyeEyeballOpen16Icon } from './EyeEyeballOpen16Icon';
import { EyeEyeballOpen20Icon } from './EyeEyeballOpen20Icon';
import { Filter12Icon } from './Filter12Icon';
import { Filter16Icon } from './Filter16Icon';
import { Filter20Icon } from './Filter20Icon';
import { Group20Icon } from './Group20Icon';
import { Guide12Icon } from './Guide12Icon';
import { Guide16Icon } from './Guide16Icon';
import { Guide20Icon } from './Guide20Icon';
import { HeightExpand12Icon } from './HeightExpand12Icon';
import { HeightExpand16Icon } from './HeightExpand16Icon';
import { HeightExpand20Icon } from './HeightExpand20Icon';
import { HelpChat12Icon } from './HelpChat12Icon';
import { HelpChat16Icon } from './HelpChat16Icon';
import { HelpChat20Icon } from './HelpChat20Icon';
import { Indicators12Icon } from './Indicators12Icon';
import { Indicators16Icon } from './Indicators16Icon';
import { Indicators20Icon } from './Indicators20Icon';
import { Left12Icon } from './Left12Icon';
import { Left16Icon } from './Left16Icon';
import { Left20Icon } from './Left20Icon';
import { Loader20Icon } from './Loader20Icon';
import { Locked12Icon } from './Locked12Icon';
import { Locked16Icon } from './Locked16Icon';
import { Locked20Icon } from './Locked20Icon';
import { Logout12Icon } from './Logout12Icon';
import { Logout16Icon } from './Logout16Icon';
import { Logout20Icon } from './Logout20Icon';
import { Menu2Lines12Icon } from './Menu2Lines12Icon';
import { Menu2Lines16Icon } from './Menu2Lines16Icon';
import { Menu2Lines20Icon } from './Menu2Lines20Icon';
import { Menu3Lines12Icon } from './Menu3Lines12Icon';
import { Menu3Lines16Icon } from './Menu3Lines16Icon';
import { Menu3Lines20Icon } from './Menu3Lines20Icon';
import { Minus20Icon } from './Minus20Icon';
import { Money12Icon } from './Money12Icon';
import { Money16Icon } from './Money16Icon';
import { Money20Icon } from './Money20Icon';
import { NewDesign12Icon } from './NewDesign12Icon';
import { NewDesign16Icon } from './NewDesign16Icon';
import { NewDesign20Icon } from './NewDesign20Icon';
import { Notifications12Icon } from './Notifications12Icon';
import { Notifications16Icon } from './Notifications16Icon';
import { Notifications20Icon } from './Notifications20Icon';
import { OldDesign12Icon } from './OldDesign12Icon';
import { OldDesign16Icon } from './OldDesign16Icon';
import { OldDesign20Icon } from './OldDesign20Icon';
import { Options16Icon } from './Options16Icon';
import { Options20Icon } from './Options20Icon';
import { OrderMain12Icon } from './OrderMain12Icon';
import { OrderMain16Icon } from './OrderMain16Icon';
import { OrderMain20Icon } from './OrderMain20Icon';
import { OrderAsc12Icon } from './OrderAsc12Icon';
import { OrderAsc16Icon } from './OrderAsc16Icon';
import { OrderAsc20Icon } from './OrderAsc20Icon';
import { OrderDesc12Icon } from './OrderDesc12Icon';
import { OrderDesc16Icon } from './OrderDesc16Icon';
import { OrderDesc20Icon } from './OrderDesc20Icon';
import { Print12Icon } from './Print12Icon';
import { Print16Icon } from './Print16Icon';
import { Print20Icon } from './Print20Icon';
import { ProductCubeModule20Icon } from './ProductCubeModule20Icon';
import { Production12Icon } from './Production12Icon';
import { Production16Icon } from './Production16Icon';
import { Production20Icon } from './Production20Icon';
import { Products12Icon } from './Products12Icon';
import { Products16Icon } from './Products16Icon';
import { Products20Icon } from './Products20Icon';
import { Purchases12Icon } from './Purchases12Icon';
import { Purchases16Icon } from './Purchases16Icon';
import { Purchases20Icon } from './Purchases20Icon';
import { Re12Icon } from './Re12Icon';
import { Re16Icon } from './Re16Icon';
import { Re20Icon } from './Re20Icon';
import { RemoveClose12Icon } from './RemoveClose12Icon';
import { RemoveClose20Icon } from './RemoveClose20Icon';
import { RemoveCloseIcon16Icon } from './RemoveCloseIcon16Icon';
import { ResetColumns20Icon } from './ResetColumns20Icon';
import { Retail12Icon } from './Retail12Icon';
import { Retail16Icon } from './Retail16Icon';
import { Retail20Icon } from './Retail20Icon';
import { Right12Icon } from './Right12Icon';
import { Right16Icon } from './Right16Icon';
import { Right20Icon } from './Right20Icon';
import { Sales12Icon } from './Sales12Icon';
import { Sales16Icon } from './Sales16Icon';
import { Sales20Icon } from './Sales20Icon';
import { Search12Icon } from './Search12Icon';
import { Search16Icon } from './Search16Icon';
import { Search20Icon } from './Search20Icon';
import { Send12Icon } from './Send12Icon';
import { Send16Icon } from './Send16Icon';
import { Send20Icon } from './Send20Icon';
import { Settings12Icon } from './Settings12Icon';
import { Settings16Icon } from './Settings16Icon';
import { Settings20Icon } from './Settings20Icon';
import { SortByCode12Icon } from './SortByCode12Icon';
import { SortByCode16Icon } from './SortByCode16Icon';
import { SortByCode20Icon } from './SortByCode20Icon';
import { Still12Icon } from './Still12Icon';
import { Still16Icon } from './Still16Icon';
import { Still20Icon } from './Still20Icon';
import { Support12Icon } from './Support12Icon';
import { Support16Icon } from './Support16Icon';
import { Support20Icon } from './Support20Icon';
import { Synchronize16Icon } from './Synchronize16Icon';
import { SynchronizeStop16Icon } from './SynchronizeStop16Icon';
import { SynchronizeWarning16Icon } from './SynchronizeWarning16Icon';
import { Tasks12Icon } from './Tasks12Icon';
import { Tasks16Icon } from './Tasks16Icon';
import { Tasks20Icon } from './Tasks20Icon';
import { TextFormattingJustified12Icon } from './TextFormattingJustified12Icon';
import { TextFormattingJustified16Icon } from './TextFormattingJustified16Icon';
import { TextFormattingJustified20Icon } from './TextFormattingJustified20Icon';
import { TurnBackward16Icon } from './TurnBackward16Icon';
import { Tutorial12Icon } from './Tutorial12Icon';
import { Tutorial16Icon } from './Tutorial16Icon';
import { Tutorial20Icon } from './Tutorial20Icon';
import { User12Icon } from './User12Icon';
import { User16Icon } from './User16Icon';
import { User20Icon } from './User20Icon';
import { Warp12Icon } from './Warp12Icon';
import { Warp16Icon } from './Warp16Icon';
import { Warp20Icon } from './Warp20Icon';
import { Zoom16Icon } from './Zoom16Icon';

export enum IconAlias {
  Adaptive12Icon = 'adaptive-12',
  Adaptive16Icon = 'adaptive-16',
  Adaptive20Icon = 'adaptive-20',
  Add12Icon = 'add-12',
  Add16Icon = 'add-16',
  Add20Icon = 'add-20',
  AlertWarningTriangle16Icon = 'alert-warning-triangle-16',
  Alphabet20Icon = 'alphabet-20',
  Applications12Icon = 'applications-12',
  Applications16Icon = 'applications-16',
  Applications20Icon = 'applications-20',
  Archive12Icon = 'archive-12',
  Archive16Icon = 'archive-16',
  Archive20Icon = 'archive-20',
  Bullet12Icon = 'bullet-12',
  Bullet16Icon = 'bullet-16',
  Check16Icon = 'check-16',
  ClearFilter20Icon = 'clear-filter-20',
  Clear12Icon = 'clear-12',
  ClipboardTextList16Icon = 'clipboard-text-list-16',
  ClipboardTextList20Icon = 'clipboard-text-list-20',
  ColumnOptions20Icon = 'column-options-20',
  Comment12Icon = 'comment-12',
  Comment16Icon = 'comment-16',
  Comment20Icon = 'comment-20',
  Create12Icon = 'create-12',
  Create16Icon = 'create-16',
  Create20Icon = 'create-20',
  Customers12Icon = 'customers-12',
  Customers16Icon = 'customers-16',
  Customers20Icon = 'customers-20',
  Date12Icon = 'date-12',
  Date16Icon = 'date-16',
  Date20Icon = 'date-20',
  Delete12Icon = 'delete-12',
  Delete16Icon = 'delete-16',
  Delete20Icon = 'delete-20',
  DocumetArrow12Icon = 'documet-arrow-12',
  DocumetArrow16Icon = 'documet-arrow-16',
  DocumetArrow20Icon = 'documet-arrow-20',
  Down12Icon = 'down-12',
  Down16Icon = 'down-16',
  Down20Icon = 'down-20',
  Drag12Icon = 'drag-12',
  DragNDrop12Icon = 'drag-n-drop-12',
  DragNDrop16Icon = 'drag-n-drop-16',
  DragNDrop20Icon = 'drag-n-drop-20',
  Edit16Icon = 'edit-16',
  EmptyFile16Icon = 'empty-file-16',
  EmptyFile20Icon = 'empty-file-20',
  EmptyFolder16Icon = 'empty-folder-16',
  EmptyFolder20Icon = 'empty-folder-20',
  EyeEyeballHide12Icon = 'eye-eyeball-hide-12',
  EyeEyeballHide16Icon = 'eye-eyeball-hide-16',
  EyeEyeballHide20Icon = 'eye-eyeball-hide-20',
  EyeEyeballOpen12Icon = 'eye-eyeball-open-12',
  EyeEyeballOpen16Icon = 'eye-eyeball-open-16',
  EyeEyeballOpen20Icon = 'eye-eyeball-open-20',
  Filter12Icon = 'filter-12',
  Filter16Icon = 'filter-16',
  Filter20Icon = 'filter-20',
  Group20Icon = 'group-20',
  Guide12Icon = 'guide-12',
  Guide16Icon = 'guide-16',
  Guide20Icon = 'guide-20',
  HeightExpand12Icon = 'height-expand-12',
  HeightExpand16Icon = 'height-expand-16',
  HeightExpand20Icon = 'height-expand-20',
  HelpChat12Icon = 'help-chat-12',
  HelpChat16Icon = 'help-chat-16',
  HelpChat20Icon = 'help-chat-20',
  Indicators12Icon = 'indicators-12',
  Indicators16Icon = 'indicators-16',
  Indicators20Icon = 'indicators-20',
  Left12Icon = 'left-12',
  Left16Icon = 'left-16',
  Left20Icon = 'left-20',
  Loader20Icon = 'loader-20',
  Locked12Icon = 'locked-12',
  Locked16Icon = 'locked-16',
  Locked20Icon = 'locked-20',
  Logout12Icon = 'logout-12',
  Logout16Icon = 'logout-16',
  Logout20Icon = 'logout-20',
  Menu2Lines12Icon = 'menu-2-lines-12',
  Menu2Lines16Icon = 'menu-2-lines-16',
  Menu2Lines20Icon = 'menu-2-lines-20',
  Menu3Lines12Icon = 'menu-3-lines-12',
  Menu3Lines16Icon = 'menu-3-lines-16',
  Menu3Lines20Icon = 'menu-3-lines-20',
  Minus20Icon = 'minus-20',
  Money12Icon = 'money-12',
  Money16Icon = 'money-16',
  Money20Icon = 'money-20',
  NewDesign12Icon = 'new-design-12',
  NewDesign16Icon = 'new-design-16',
  NewDesign20Icon = 'new-design-20',
  Notifications12Icon = 'notifications-12',
  Notifications16Icon = 'notifications-16',
  Notifications20Icon = 'notifications-20',
  OldDesign12Icon = 'old-design-12',
  OldDesign16Icon = 'old-design-16',
  OldDesign20Icon = 'old-design-20',
  Options16Icon = 'options-16',
  Options20Icon = 'options-20',
  OrderMain12Icon = 'order-main-12',
  OrderMain16Icon = 'order-main-16',
  OrderMain20Icon = 'order-main-20',
  OrderAsc12Icon = 'order-asc-12',
  OrderAsc16Icon = 'order-asc-16',
  OrderAsc20Icon = 'order-asc-20',
  OrderDesc12Icon = 'order-desc-12',
  OrderDesc16Icon = 'order-desc-16',
  OrderDesc20Icon = 'order-desc-20',
  Print12Icon = 'print-12',
  Print16Icon = 'print-16',
  Print20Icon = 'print-20',
  ProductCubeModule20Icon = 'product-cube-module-20',
  Production12Icon = 'production-12',
  Production16Icon = 'production-16',
  Production20Icon = 'production-20',
  Products12Icon = 'products-12',
  Products16Icon = 'products-16',
  Products20Icon = 'products-20',
  Purchases12Icon = 'purchases-12',
  Purchases16Icon = 'purchases-16',
  Purchases20Icon = 'purchases-20',
  Re12Icon = 're-12',
  Re16Icon = 're-16',
  Re20Icon = 're-20',
  RemoveClose12Icon = 'remove-close-12',
  RemoveClose20Icon = 'remove-close-20',
  RemoveCloseIcon16Icon = 'remove-close-icon-16',
  ResetColumns20Icon = 'reset-columns-20',
  Retail12Icon = 'retail-12',
  Retail16Icon = 'retail-16',
  Retail20Icon = 'retail-20',
  Right12Icon = 'right-12',
  Right16Icon = 'right-16',
  Right20Icon = 'right-20',
  Sales12Icon = 'sales-12',
  Sales16Icon = 'sales-16',
  Sales20Icon = 'sales-20',
  Search12Icon = 'search-12',
  Search16Icon = 'search-16',
  Search20Icon = 'search-20',
  Send12Icon = 'send-12',
  Send16Icon = 'send-16',
  Send20Icon = 'send-20',
  Settings12Icon = 'settings-12',
  Settings16Icon = 'settings-16',
  Settings20Icon = 'settings-20',
  SortByCode12Icon = 'sort-by-code-12',
  SortByCode16Icon = 'sort-by-code-16',
  SortByCode20Icon = 'sort-by-code-20',
  Still12Icon = 'still-12',
  Still16Icon = 'still-16',
  Still20Icon = 'still-20',
  Support12Icon = 'support-12',
  Support16Icon = 'support-16',
  Support20Icon = 'support-20',
  Synchronize16Icon = 'synchronize-16',
  SynchronizeStop16Icon = 'synchronize-stop-16',
  SynchronizeWarning16Icon = 'synchronize-warning-16',
  Tasks12Icon = 'tasks-12',
  Tasks16Icon = 'tasks-16',
  Tasks20Icon = 'tasks-20',
  TextFormattingJustified12Icon = 'text-formatting-justified-12',
  TextFormattingJustified16Icon = 'text-formatting-justified-16',
  TextFormattingJustified20Icon = 'text-formatting-justified-20',
  TurnBackward16Icon = 'turn-backward-16',
  Tutorial12Icon = 'tutorial-12',
  Tutorial16Icon = 'tutorial-16',
  Tutorial20Icon = 'tutorial-20',
  User12Icon = 'user-12',
  User16Icon = 'user-16',
  User20Icon = 'user-20',
  Warp12Icon = 'warp-12',
  Warp16Icon = 'warp-16',
  Warp20Icon = 'warp-20',
  Zoom16Icon = 'zoom-16',
}

export const iconMapGenerated = {
  [IconAlias.Adaptive12Icon]: Adaptive12Icon,
  [IconAlias.Adaptive16Icon]: Adaptive16Icon,
  [IconAlias.Adaptive20Icon]: Adaptive20Icon,
  [IconAlias.Add12Icon]: Add12Icon,
  [IconAlias.Add16Icon]: Add16Icon,
  [IconAlias.Add20Icon]: Add20Icon,
  [IconAlias.AlertWarningTriangle16Icon]: AlertWarningTriangle16Icon,
  [IconAlias.Alphabet20Icon]: Alphabet20Icon,
  [IconAlias.Applications12Icon]: Applications12Icon,
  [IconAlias.Applications16Icon]: Applications16Icon,
  [IconAlias.Applications20Icon]: Applications20Icon,
  [IconAlias.Archive12Icon]: Archive12Icon,
  [IconAlias.Archive16Icon]: Archive16Icon,
  [IconAlias.Archive20Icon]: Archive20Icon,
  [IconAlias.Bullet12Icon]: Bullet12Icon,
  [IconAlias.Bullet16Icon]: Bullet16Icon,
  [IconAlias.Check16Icon]: Check16Icon,
  [IconAlias.ClearFilter20Icon]: ClearFilter20Icon,
  [IconAlias.Clear12Icon]: Clear12Icon,
  [IconAlias.ClipboardTextList16Icon]: ClipboardTextList16Icon,
  [IconAlias.ClipboardTextList20Icon]: ClipboardTextList20Icon,
  [IconAlias.ColumnOptions20Icon]: ColumnOptions20Icon,
  [IconAlias.Comment12Icon]: Comment12Icon,
  [IconAlias.Comment16Icon]: Comment16Icon,
  [IconAlias.Comment20Icon]: Comment20Icon,
  [IconAlias.Create12Icon]: Create12Icon,
  [IconAlias.Create16Icon]: Create16Icon,
  [IconAlias.Create20Icon]: Create20Icon,
  [IconAlias.Customers12Icon]: Customers12Icon,
  [IconAlias.Customers16Icon]: Customers16Icon,
  [IconAlias.Customers20Icon]: Customers20Icon,
  [IconAlias.Date12Icon]: Date12Icon,
  [IconAlias.Date16Icon]: Date16Icon,
  [IconAlias.Date20Icon]: Date20Icon,
  [IconAlias.Delete12Icon]: Delete12Icon,
  [IconAlias.Delete16Icon]: Delete16Icon,
  [IconAlias.Delete20Icon]: Delete20Icon,
  [IconAlias.DocumetArrow12Icon]: DocumetArrow12Icon,
  [IconAlias.DocumetArrow16Icon]: DocumetArrow16Icon,
  [IconAlias.DocumetArrow20Icon]: DocumetArrow20Icon,
  [IconAlias.Down12Icon]: Down12Icon,
  [IconAlias.Down16Icon]: Down16Icon,
  [IconAlias.Down20Icon]: Down20Icon,
  [IconAlias.Drag12Icon]: Drag12Icon,
  [IconAlias.DragNDrop12Icon]: DragNDrop12Icon,
  [IconAlias.DragNDrop16Icon]: DragNDrop16Icon,
  [IconAlias.DragNDrop20Icon]: DragNDrop20Icon,
  [IconAlias.Edit16Icon]: Edit16Icon,
  [IconAlias.EmptyFile16Icon]: EmptyFile16Icon,
  [IconAlias.EmptyFile20Icon]: EmptyFile20Icon,
  [IconAlias.EmptyFolder16Icon]: EmptyFolder16Icon,
  [IconAlias.EmptyFolder20Icon]: EmptyFolder20Icon,
  [IconAlias.EyeEyeballHide12Icon]: EyeEyeballHide12Icon,
  [IconAlias.EyeEyeballHide16Icon]: EyeEyeballHide16Icon,
  [IconAlias.EyeEyeballHide20Icon]: EyeEyeballHide20Icon,
  [IconAlias.EyeEyeballOpen12Icon]: EyeEyeballOpen12Icon,
  [IconAlias.EyeEyeballOpen16Icon]: EyeEyeballOpen16Icon,
  [IconAlias.EyeEyeballOpen20Icon]: EyeEyeballOpen20Icon,
  [IconAlias.Filter12Icon]: Filter12Icon,
  [IconAlias.Filter16Icon]: Filter16Icon,
  [IconAlias.Filter20Icon]: Filter20Icon,
  [IconAlias.Group20Icon]: Group20Icon,
  [IconAlias.Guide12Icon]: Guide12Icon,
  [IconAlias.Guide16Icon]: Guide16Icon,
  [IconAlias.Guide20Icon]: Guide20Icon,
  [IconAlias.HeightExpand12Icon]: HeightExpand12Icon,
  [IconAlias.HeightExpand16Icon]: HeightExpand16Icon,
  [IconAlias.HeightExpand20Icon]: HeightExpand20Icon,
  [IconAlias.HelpChat12Icon]: HelpChat12Icon,
  [IconAlias.HelpChat16Icon]: HelpChat16Icon,
  [IconAlias.HelpChat20Icon]: HelpChat20Icon,
  [IconAlias.Indicators12Icon]: Indicators12Icon,
  [IconAlias.Indicators16Icon]: Indicators16Icon,
  [IconAlias.Indicators20Icon]: Indicators20Icon,
  [IconAlias.Left12Icon]: Left12Icon,
  [IconAlias.Left16Icon]: Left16Icon,
  [IconAlias.Left20Icon]: Left20Icon,
  [IconAlias.Loader20Icon]: Loader20Icon,
  [IconAlias.Locked12Icon]: Locked12Icon,
  [IconAlias.Locked16Icon]: Locked16Icon,
  [IconAlias.Locked20Icon]: Locked20Icon,
  [IconAlias.Logout12Icon]: Logout12Icon,
  [IconAlias.Logout16Icon]: Logout16Icon,
  [IconAlias.Logout20Icon]: Logout20Icon,
  [IconAlias.Menu2Lines12Icon]: Menu2Lines12Icon,
  [IconAlias.Menu2Lines16Icon]: Menu2Lines16Icon,
  [IconAlias.Menu2Lines20Icon]: Menu2Lines20Icon,
  [IconAlias.Menu3Lines12Icon]: Menu3Lines12Icon,
  [IconAlias.Menu3Lines16Icon]: Menu3Lines16Icon,
  [IconAlias.Menu3Lines20Icon]: Menu3Lines20Icon,
  [IconAlias.Minus20Icon]: Minus20Icon,
  [IconAlias.Money12Icon]: Money12Icon,
  [IconAlias.Money16Icon]: Money16Icon,
  [IconAlias.Money20Icon]: Money20Icon,
  [IconAlias.NewDesign12Icon]: NewDesign12Icon,
  [IconAlias.NewDesign16Icon]: NewDesign16Icon,
  [IconAlias.NewDesign20Icon]: NewDesign20Icon,
  [IconAlias.Notifications12Icon]: Notifications12Icon,
  [IconAlias.Notifications16Icon]: Notifications16Icon,
  [IconAlias.Notifications20Icon]: Notifications20Icon,
  [IconAlias.OldDesign12Icon]: OldDesign12Icon,
  [IconAlias.OldDesign16Icon]: OldDesign16Icon,
  [IconAlias.OldDesign20Icon]: OldDesign20Icon,
  [IconAlias.Options16Icon]: Options16Icon,
  [IconAlias.Options20Icon]: Options20Icon,
  [IconAlias.OrderMain12Icon]: OrderMain12Icon,
  [IconAlias.OrderMain16Icon]: OrderMain16Icon,
  [IconAlias.OrderMain20Icon]: OrderMain20Icon,
  [IconAlias.OrderAsc12Icon]: OrderAsc12Icon,
  [IconAlias.OrderAsc16Icon]: OrderAsc16Icon,
  [IconAlias.OrderAsc20Icon]: OrderAsc20Icon,
  [IconAlias.OrderDesc12Icon]: OrderDesc12Icon,
  [IconAlias.OrderDesc16Icon]: OrderDesc16Icon,
  [IconAlias.OrderDesc20Icon]: OrderDesc20Icon,
  [IconAlias.Print12Icon]: Print12Icon,
  [IconAlias.Print16Icon]: Print16Icon,
  [IconAlias.Print20Icon]: Print20Icon,
  [IconAlias.ProductCubeModule20Icon]: ProductCubeModule20Icon,
  [IconAlias.Production12Icon]: Production12Icon,
  [IconAlias.Production16Icon]: Production16Icon,
  [IconAlias.Production20Icon]: Production20Icon,
  [IconAlias.Products12Icon]: Products12Icon,
  [IconAlias.Products16Icon]: Products16Icon,
  [IconAlias.Products20Icon]: Products20Icon,
  [IconAlias.Purchases12Icon]: Purchases12Icon,
  [IconAlias.Purchases16Icon]: Purchases16Icon,
  [IconAlias.Purchases20Icon]: Purchases20Icon,
  [IconAlias.Re12Icon]: Re12Icon,
  [IconAlias.Re16Icon]: Re16Icon,
  [IconAlias.Re20Icon]: Re20Icon,
  [IconAlias.RemoveClose12Icon]: RemoveClose12Icon,
  [IconAlias.RemoveClose20Icon]: RemoveClose20Icon,
  [IconAlias.RemoveCloseIcon16Icon]: RemoveCloseIcon16Icon,
  [IconAlias.ResetColumns20Icon]: ResetColumns20Icon,
  [IconAlias.Retail12Icon]: Retail12Icon,
  [IconAlias.Retail16Icon]: Retail16Icon,
  [IconAlias.Retail20Icon]: Retail20Icon,
  [IconAlias.Right12Icon]: Right12Icon,
  [IconAlias.Right16Icon]: Right16Icon,
  [IconAlias.Right20Icon]: Right20Icon,
  [IconAlias.Sales12Icon]: Sales12Icon,
  [IconAlias.Sales16Icon]: Sales16Icon,
  [IconAlias.Sales20Icon]: Sales20Icon,
  [IconAlias.Search12Icon]: Search12Icon,
  [IconAlias.Search16Icon]: Search16Icon,
  [IconAlias.Search20Icon]: Search20Icon,
  [IconAlias.Send12Icon]: Send12Icon,
  [IconAlias.Send16Icon]: Send16Icon,
  [IconAlias.Send20Icon]: Send20Icon,
  [IconAlias.Settings12Icon]: Settings12Icon,
  [IconAlias.Settings16Icon]: Settings16Icon,
  [IconAlias.Settings20Icon]: Settings20Icon,
  [IconAlias.SortByCode12Icon]: SortByCode12Icon,
  [IconAlias.SortByCode16Icon]: SortByCode16Icon,
  [IconAlias.SortByCode20Icon]: SortByCode20Icon,
  [IconAlias.Still12Icon]: Still12Icon,
  [IconAlias.Still16Icon]: Still16Icon,
  [IconAlias.Still20Icon]: Still20Icon,
  [IconAlias.Support12Icon]: Support12Icon,
  [IconAlias.Support16Icon]: Support16Icon,
  [IconAlias.Support20Icon]: Support20Icon,
  [IconAlias.Synchronize16Icon]: Synchronize16Icon,
  [IconAlias.SynchronizeStop16Icon]: SynchronizeStop16Icon,
  [IconAlias.SynchronizeWarning16Icon]: SynchronizeWarning16Icon,
  [IconAlias.Tasks12Icon]: Tasks12Icon,
  [IconAlias.Tasks16Icon]: Tasks16Icon,
  [IconAlias.Tasks20Icon]: Tasks20Icon,
  [IconAlias.TextFormattingJustified12Icon]: TextFormattingJustified12Icon,
  [IconAlias.TextFormattingJustified16Icon]: TextFormattingJustified16Icon,
  [IconAlias.TextFormattingJustified20Icon]: TextFormattingJustified20Icon,
  [IconAlias.TurnBackward16Icon]: TurnBackward16Icon,
  [IconAlias.Tutorial12Icon]: Tutorial12Icon,
  [IconAlias.Tutorial16Icon]: Tutorial16Icon,
  [IconAlias.Tutorial20Icon]: Tutorial20Icon,
  [IconAlias.User12Icon]: User12Icon,
  [IconAlias.User16Icon]: User16Icon,
  [IconAlias.User20Icon]: User20Icon,
  [IconAlias.Warp12Icon]: Warp12Icon,
  [IconAlias.Warp16Icon]: Warp16Icon,
  [IconAlias.Warp20Icon]: Warp20Icon,
  [IconAlias.Zoom16Icon]: Zoom16Icon,
};
