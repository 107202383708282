/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Sales12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M3.857 4.857V3.571a2.143 2.143 0 1 1 4.286 0v1.286m2.897 6.763a.857.857 0 0 1-.857.951H1.817a.857.857 0 0 1-.857-.951l.754-6.763h8.572l.754 6.763Z"
      />
    </svg>
  );
};

Sales12Icon.displayName = 'Sales12Icon';
