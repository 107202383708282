/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const HeightExpand12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M7 10.857V3.143m0 7.714 2.143-2.143M7 10.857 4.857 8.714M7 3.143l2.143 2.143M7 3.143 4.857 5.286m7.715-3.857H1.429M12.572 12.57H1.429"
      />
    </svg>
  );
};

HeightExpand12Icon.displayName = 'HeightExpand12Icon';
