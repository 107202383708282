/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Search12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M12.572 12.571 9.357 9.357m1.363-3.283a4.646 4.646 0 1 1-9.292 0 4.646 4.646 0 0 1 9.292 0Z"
      />
    </svg>
  );
};

Search12Icon.displayName = 'Search12Icon';
