/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Menu2Lines12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 13"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M9.857 8.237H2.143a.857.857 0 0 0-.857.857v1.715c0 .473.383.857.857.857h7.714a.857.857 0 0 0 .857-.857V9.094a.857.857 0 0 0-.857-.857Zm0-7.714H2.143a.857.857 0 0 0-.857.857v1.714c0 .474.383.857.857.857h7.714a.857.857 0 0 0 .857-.857V1.38a.857.857 0 0 0-.857-.857Z"
      />
    </svg>
  );
};

Menu2Lines12Icon.displayName = 'Menu2Lines12Icon';
