/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Settings20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="m7.472 3.214.614-1.585A1.429 1.429 0 0 1 9.415.714h1.17a1.429 1.429 0 0 1 1.33.915l.614 1.585 2.085 1.2 1.686-.257a1.429 1.429 0 0 1 1.429.7l.571 1a1.428 1.428 0 0 1-.114 1.614L17.143 8.8v2.4l1.072 1.329a1.428 1.428 0 0 1 .114 1.614l-.572 1a1.429 1.429 0 0 1-1.428.7l-1.686-.257-2.086 1.2-.614 1.585a1.428 1.428 0 0 1-1.329.915h-1.2a1.429 1.429 0 0 1-1.328-.915l-.614-1.585-2.086-1.2-1.686.257a1.429 1.429 0 0 1-1.428-.7l-.572-1a1.429 1.429 0 0 1 .115-1.615L2.857 11.2V8.8L1.786 7.471a1.429 1.429 0 0 1-.114-1.614l.571-1a1.429 1.429 0 0 1 1.429-.7l1.685.257 2.115-1.2ZM7.143 10a2.857 2.857 0 1 0 5.715 0 2.857 2.857 0 0 0-5.715 0Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Settings20Icon.displayName = 'Settings20Icon';
