/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Menu2Lines20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M16.429 13.729H3.57c-.789 0-1.428.64-1.428 1.428v2.857c0 .79.64 1.429 1.428 1.429h12.86c.789 0 1.428-.64 1.428-1.429v-2.857c0-.789-.64-1.428-1.428-1.428Zm0-12.858H3.57c-.789 0-1.428.64-1.428 1.429v2.857c0 .789.64 1.429 1.428 1.429h12.86c.789 0 1.428-.64 1.428-1.429V2.3c0-.79-.64-1.429-1.428-1.429Z"
      />
    </svg>
  );
};

Menu2Lines20Icon.displayName = 'Menu2Lines20Icon';
