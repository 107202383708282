/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Production12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M8.286 12.571H1.428V4.43l3.429-3 3.429 3v8.142Zm0 0h4.285v-6H8.286m-3.429 6v-1.714"
      />
    </svg>
  );
};

Production12Icon.displayName = 'Production12Icon';
