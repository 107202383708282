/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const DocumetArrow16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.857 8.571V1.714A1.143 1.143 0 0 1 4 .571h5.714m0 0 5.715 5.715M9.714.57v5.715h5.715m0 0v8a1.143 1.143 0 0 1-1.143 1.143H7.429m-3.946 0 2.286-2.286m0 0-2.286-2.286m2.286 2.286H.626"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

DocumetArrow16Icon.displayName = 'DocumetArrow16Icon';
