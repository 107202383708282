/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const NewDesign16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m15.429 6.971-7.429 8m7.429-8L12 2.4m3.429 4.571H.572m7.428 8-7.428-8m7.428 8v-8m-7.428 0L4 2.4m0 0h8m-8 0 4 4.571M12 2.4 8 6.971"
      />
    </svg>
  );
};

NewDesign16Icon.displayName = 'NewDesign16Icon';
