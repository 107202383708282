/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const OldDesign16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 .572s5.715 5.714 5.715 9.714A5.451 5.451 0 0 1 8 15.429M8 .572s-5.714 5.714-5.714 9.714A5.451 5.451 0 0 0 8 15.429M8 .572v14.857m2.858-.686V3.932"
      />
    </svg>
  );
};

OldDesign16Icon.displayName = 'OldDesign16Icon';
