/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Group20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill={stroke}
      viewBox="0 0 20 20"
    >
      <path
        fill={stroke}
        fillRule="evenodd"
        d="M9.128.63a2.193 2.193 0 0 1 1.744 0l.012.006 7.919 3.658.006.003.01.005a1.327 1.327 0 0 1 0 2.368l-.015.008-7.919 3.685-.013.006a2.192 2.192 0 0 1-1.744 0l-.012-.005-7.919-3.658a1.327 1.327 0 0 1-.55-1.888c.13-.209.314-.378.534-.488l.015-.008L9.115.637 9.128.63Zm.444 1.007L1.67 5.315a.227.227 0 0 0-.12.199.224.224 0 0 0 .12.198l7.901 3.65a1.093 1.093 0 0 0 .857 0l7.902-3.677a.227.227 0 0 0 .12-.199.225.225 0 0 0-.12-.198l-7.901-3.65a1.093 1.093 0 0 0-.857 0Z"
        clipRule="evenodd"
      />
      <path
        fill={stroke}
        d="M19.5 10.27a.55.55 0 0 1-.27.73l-8.447 3.876h-.002a1.94 1.94 0 0 1-1.604 0h-.003L.77 10.998A.55.55 0 0 1 1.23 10l8.402 3.874h.001a.84.84 0 0 0 .693 0L18.77 10a.55.55 0 0 1 .729.27Z"
      />
      <path
        fill={stroke}
        d="M19.23 15.5a.55.55 0 1 0-.46-1l-8.444 3.875a.843.843 0 0 1-.693 0L1.23 14.5a.55.55 0 0 0-.46.998l8.404 3.876.003.001a1.94 1.94 0 0 0 1.604 0h.002L19.23 15.5Z"
      />

      <defs>
        <clipPath id="a">
          <path fill={stroke} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Group20Icon.displayName = 'Group20Icon';
