/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const EmptyFile16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.571.571H2.857a1.143 1.143 0 0 0-1.143 1.143v12.572a1.143 1.143 0 0 0 1.143 1.143h10.286a1.143 1.143 0 0 0 1.143-1.143v-8M8.57.57l5.715 5.715M8.57.57v5.715h5.715"
      />
    </svg>
  );
};

EmptyFile16Icon.displayName = 'EmptyFile16Icon';
