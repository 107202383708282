/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Guide12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 13"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M10.714 11.214a.857.857 0 0 1-.857.857H2.143a.857.857 0 0 1-.857-.857V1.786a.857.857 0 0 1 .857-.857h5.571l3 3v7.285Z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M6.857 6.929 5.143 5.214 3.429 6.93v-6h3.428v6Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" transform="translate(0 .5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

Guide12Icon.displayName = 'Guide12Icon';
