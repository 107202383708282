/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Comment12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="m4.857 11.714-3.428.857L2.286 10V2.286a.857.857 0 0 1 .857-.857h8.571a.857.857 0 0 1 .858.857v8.571a.857.857 0 0 1-.858.857H4.857Z"
      />
    </svg>
  );
};

Comment12Icon.displayName = 'Comment12Icon';
