/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Archive12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M1.286 4.749h9.428M1.286 2.177h9.428m.857 6a.857.857 0 0 0-.857-.857H8.143v.857H3.857V7.32H1.286a.857.857 0 0 0-.858.857v2.572a.857.857 0 0 0 .858.857h9.428a.857.857 0 0 0 .857-.857V8.177Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Archive12Icon.displayName = 'Archive12Icon';
