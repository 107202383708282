/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Purchases16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.571 13.714h8.697c.3.002.588.12.8.332l1.36 1.383M4.571 13.713a1.988 1.988 0 1 0-3.966-.286 1.988 1.988 0 0 0 3.966.286ZM.571.571h1.143a1.143 1.143 0 0 1 1.143 1.143v9.715m4.571-8h5.143c.632 0 1.143.511 1.143 1.142v5.143c0 .631-.511 1.143-1.143 1.143H7.428a1.143 1.143 0 0 1-1.142-1.143V4.571c0-.63.511-1.142 1.143-1.142Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Purchases16Icon.displayName = 'Purchases16Icon';
