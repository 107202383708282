/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Guide16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.286 14.286a1.143 1.143 0 0 1-1.143 1.143H2.857a1.143 1.143 0 0 1-1.143-1.143V1.714A1.143 1.143 0 0 1 2.857.571h7.429l4 4v9.715Z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.143 8.571 6.857 6.286 4.572 8.57v-8h4.57v8Z"
      />
    </svg>
  );
};

Guide16Icon.displayName = 'Guide16Icon';
