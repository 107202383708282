import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const ChevronDown: FC<SingleIconProps> = ({
  stroke = '#ffffff',
  width = 16,
  height = 10,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.571289 1.3999L7.59986 8.42847C7.65116 8.48314 7.71313 8.52671 7.78192 8.55649C7.85072 8.58627 7.92489 8.60164 7.99986 8.60164C8.07483 8.60164 8.149 8.58627 8.2178 8.55649C8.2866 8.52671 8.34856 8.48314 8.39986 8.42847L15.4284 1.3999"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ChevronDown.displayName = 'ChevronDown';
