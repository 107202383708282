/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const User20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M4.146 17.083a7.054 7.054 0 0 1 12.048 0m-2.497-9.03a3.527 3.527 0 1 1-7.054 0 3.527 3.527 0 0 1 7.054 0Zm5.643 2.117a9.17 9.17 0 1 1-18.34 0 9.17 9.17 0 0 1 18.34 0Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

User20Icon.displayName = 'User20Icon';
