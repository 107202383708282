/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const ClipboardTextList16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.857 1.714h1.715a1.143 1.143 0 0 1 1.142 1.143v11.429a1.143 1.143 0 0 1-1.143 1.143H3.43a1.143 1.143 0 0 1-1.143-1.143V2.857A1.143 1.143 0 0 1 3.43 1.714h1.714m5.714 0c0-.63-.511-1.143-1.143-1.143H6.286c-.631 0-1.143.512-1.143 1.143m5.714 0v.572c0 .63-.511 1.143-1.143 1.143H6.286a1.143 1.143 0 0 1-1.143-1.143v-.572m0 4.572h5.714M5.143 9.143h5.714M5.143 12h5.714"
      />
    </svg>
  );
};

ClipboardTextList16Icon.displayName = 'ClipboardTextList16Icon';
