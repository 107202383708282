/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Delete16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.143 4h13.714M5.143 4v-.571a2.857 2.857 0 0 1 5.714 0V4M6.286 6.286v6.285m3.428-6.285v6.285M2.857 4h10.286v10.286A1.143 1.143 0 0 1 12 15.429H4a1.143 1.143 0 0 1-1.143-1.143V4Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Delete16Icon.displayName = 'Delete16Icon';
