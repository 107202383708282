import React, { createElement, FC } from 'react';

import { IconAlias, iconMapGenerated } from '../generated';

export interface IconProps {
  alias: `${IconAlias}`;
  color?: string;
  width?: number;
  height?: number;
}

export const Icon: FC<IconProps> = ({ alias, color, width, height }) => {
  return createElement(iconMapGenerated[alias], { stroke: color, width, height });
};

Icon.displayName = 'Icon';
