/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Notifications12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M5.714 11.569a1.328 1.328 0 0 0 2.572 0M7 1.429a3.677 3.677 0 0 1 3.677 3.677c0 4.088 1.492 4.894 1.894 4.894H1.428c.412 0 1.895-.814 1.895-4.894A3.677 3.677 0 0 1 7 1.429Z"
      />
    </svg>
  );
};

Notifications12Icon.displayName = 'Notifications12Icon';
