/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Logout16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.857 12v2.286a1.143 1.143 0 0 1-1.143 1.143h-8a1.143 1.143 0 0 1-1.143-1.143V1.714A1.143 1.143 0 0 1 1.714.571h8a1.143 1.143 0 0 1 1.143 1.143V4M7.43 8h8m0 0-2.286-2.286M15.429 8l-2.286 2.286"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Logout16Icon.displayName = 'Logout16Icon';
