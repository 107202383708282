/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Adaptive12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M7.923 5.615H1m10-2.307H1M11 1H1m0 9.615V8.308c0-.213.172-.385.385-.385h9.23c.213 0 .385.172.385.385v2.307a.385.385 0 0 1-.385.385h-9.23A.385.385 0 0 1 1 10.615Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Adaptive12Icon.displayName = 'Adaptive12Icon';
