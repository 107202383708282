import React, {
  ButtonHTMLAttributes,
  PropsWithChildren,
  forwardRef,
  useCallback,
} from 'react';

import { css, cx } from '@linaria/core';

import {
  ALSHaussFontFamily,
  background_click_background_color_primary_button,
  background_disable_background_color_primary_button,
  background_hover_background_color_primary_button,
  background_normal_background_color_primary_button,
  border_radius_8px_round,
  color_disable_font_color_primary_button,
  color_normal_font_color_primary_button,
  font_size_1,
  line_height_2,
} from './tokenStyles';

import { IHasTestId } from '@lognex/ui-common/';
import { Icon, IconAlias } from '@lognex/ui-icon';

export enum ButtonVariants {
  PRIMARY = 'primary',
  ACCENT = 'accent',
  ADDITIONAL = 'additional',
  FRAMELESS = 'frameless',
  SECONDARY = 'secondary',
}

export enum ButtonSize {
  L = 'l',
  XL = 'xl',
}

export interface IButtonProps
  extends PropsWithChildren<ButtonHTMLAttributes<HTMLInputElement>>,
    IHasTestId {
  variant?: `${ButtonVariants}`;
  iconPrepend?: IconAlias;
  iconAppend?: IconAlias;
  size: `${ButtonSize}`;
}

export const variantButton = {
  [ButtonVariants.PRIMARY]: {
    cssVariantButton: css`
      ${background_normal_background_color_primary_button}
      ${color_normal_font_color_primary_button}

      border: none;

      ${border_radius_8px_round}

      &:hover {
        ${background_hover_background_color_primary_button}
      }

      &:active {
        ${background_click_background_color_primary_button}
      }
    `,
    cssDisabled: css`
      &&& {
        ${background_disable_background_color_primary_button}
        ${color_disable_font_color_primary_button}
        cursor: initial;

        svg path {
          stroke: ${color_disable_font_color_primary_button.color};
        }
      }
    `,
  },

  [ButtonVariants.ACCENT]: {
    cssVariantButton: css`
      background: linear-gradient(271.79deg, #fe4b1d 1.52%, #ff7732 98.48%);
      color: #ffffff;

      border: none;

      ${border_radius_8px_round}

      &:hover {
        background: #ee4317;
      }

      &:active {
        background: #fe5924;
      }
    `,
    cssDisabled: css`
      &&& {
        ${background_disable_background_color_primary_button}
        ${color_disable_font_color_primary_button}
        cursor: initial;

        svg path {
          stroke: ${color_disable_font_color_primary_button.color};
        }
      }
    `,
  },

  [ButtonVariants.ADDITIONAL]: {
    cssVariantButton: css`
      background: #ffffff00;
      color: #036ce5;

      border: none;

      outline: 1px solid #036ce5;

      ${border_radius_8px_round}

      svg path {
        stroke: #036ce5;
      }

      &:hover {
        color: #005dc9;
        outline: 1px solid #005dc9;

        svg path {
          stroke: #005dc9;
        }
      }

      &:active {
        color: #0b7cff;
        outline: 1px solid #0b7cff;

        svg path {
          stroke: #0b7cff;
        }
      }
    `,
    cssDisabled: css`
      &&& {
        background: #ffffff;
        color: #797979;
        cursor: initial;

        outline: 1px solid #cbd0db;

        svg path {
          stroke: #797979;
        }
      }
    `,
  },

  [ButtonVariants.FRAMELESS]: {
    cssVariantButton: css`
      && {
        padding-right: 0;
        padding-left: 0;
      }

      background: #ffffff00;
      color: #036ce5;

      border-radius: 0;
      border: none;

      ${border_radius_8px_round}

      svg path {
        stroke: #036ce5;
      }

      &:hover {
        color: #005dc9;

        svg path {
          stroke: #005dc9;
        }
      }

      &:active {
        color: #0b7cff;

        svg path {
          stroke: #0b7cff;
        }
      }
    `,
    cssDisabled: css`
      &&& {
        background: #ffffff;
        color: #797979;
        cursor: initial;

        svg path {
          stroke: #797979;
        }
      }
    `,
  },

  [ButtonVariants.SECONDARY]: {
    cssVariantButton: css`
      background: #ffffff;
      color: #091739;

      border: none;
      outline: 1px solid #cbd0db;

      ${border_radius_8px_round}

      svg path {
        stroke: #091739;
      }

      &:hover {
        background: #e7eaf0;
      }

      &:active {
        background: #f1f4f9;
      }
    `,
    cssDisabled: css`
      &&& {
        ${background_disable_background_color_primary_button}
        ${color_disable_font_color_primary_button}
        cursor: initial;

        svg path {
          stroke: ${color_disable_font_color_primary_button.color};
        }
      }
    `,
  },
};

const cssButton = css`
  && {
    ${line_height_2}
    ${font_size_1}
    ${ALSHaussFontFamily}
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
`;

const sizeButton = {
  [ButtonSize.L]: css`
    padding: 6px 16px;
  `,
  [ButtonSize.XL]: css`
    padding: 10px 16px;
  `,
};

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (props, ref) => {
    const {
      testId,
      type = 'button',
      variant = ButtonVariants.PRIMARY,
      disabled,
      onClick,
      className: classNameProp,
      children,
      iconPrepend,
      iconAppend,
      size = ButtonSize.L,
      ...rest
    } = props;
    const { cssVariantButton, cssDisabled } = variantButton[variant];
    const cssSize = sizeButton[size];

    const className = cx(
      classNameProp,
      cssButton,
      cssVariantButton,
      disabled && cssDisabled,
      cssSize
    );
    const handleClick = useCallback(
      (e) => {
        if (!disabled && onClick) {
          onClick(e);
        }
      },
      [disabled, onClick]
    );

    return (
      <button
        {...rest}
        ref={ref}
        data-test-id={testId}
        type={type}
        className={className}
        onClick={handleClick}
      >
        {iconPrepend && <Icon alias={iconPrepend} />}
        {children}
        {iconAppend && <Icon alias={iconAppend} />}
      </button>
    );
  }
);

Button.displayName = 'Button';
