/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Sales20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M6.429 6.429V4.286a3.571 3.571 0 0 1 7.143 0v2.143M18.4 17.7a1.43 1.43 0 0 1-.842 1.465 1.429 1.429 0 0 1-.586.12H3.029A1.428 1.428 0 0 1 1.6 17.7L2.857 6.429h14.286L18.4 17.7Z"
      />
    </svg>
  );
};

Sales20Icon.displayName = 'Sales20Icon';
