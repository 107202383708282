/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Synchronize16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m12.571 10.286 2.286-.572m0 0L15.43 12m-.572-2.286A7.726 7.726 0 0 1 8 14.857a6.858 6.858 0 0 1-6.446-4.514M3.43 5.714l-2.286.572m0 0L.57 4m.572 2.286C2.103 3.657 5.05 1.143 8 1.143a6.857 6.857 0 0 1 6.446 4.571"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Synchronize16Icon.displayName = 'Synchronize16Icon';
