/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const EmptyFolder20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M19.286 8.571a2.143 2.143 0 0 0-2.143-2.142H10L7.943 2.143H2.857A2.143 2.143 0 0 0 .714 4.286v11.428a2.143 2.143 0 0 0 2.143 2.143h14.286a2.143 2.143 0 0 0 2.143-2.143V8.571Z"
      />
    </svg>
  );
};

EmptyFolder20Icon.displayName = 'EmptyFolder20Icon';
