/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Options16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 2.286a1.714 1.714 0 1 1-3.428 0 1.714 1.714 0 0 1 3.428 0Zm0 0h11.43M9.714 8a1.714 1.714 0 1 1-3.428 0m3.428 0a1.714 1.714 0 0 0-3.428 0m3.428 0h5.715M6.286 8H.572M12 13.714a1.714 1.714 0 1 0 3.429 0 1.714 1.714 0 0 0-3.429 0Zm0 0H.572"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Options16Icon.displayName = 'Options16Icon';
