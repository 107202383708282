import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const DropWithLinesIcon: FC<SingleIconProps> = ({
  stroke = '#ffffff',
  width = 14,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00042 0.571533C7.00042 0.571533 12.7147 6.28582 12.7147 10.2858C12.635 11.7241 11.9892 13.0724 10.9185 14.036C9.84774 14.9997 8.43913 15.5003 7.00042 15.4287M7.00042 0.571533C7.00042 0.571533 1.28613 6.28582 1.28613 10.2858C1.36589 11.7241 2.01167 13.0724 3.08239 14.036C4.1531 14.9997 5.5617 15.5003 7.00042 15.4287M7.00042 0.571533V15.4287M9.85756 14.743V3.93153"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

DropWithLinesIcon.displayName = 'DropWithLinesIcon';
