/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const HelpChat20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M7.857 6.429A2.143 2.143 0 1 1 10 8.57v.715m0 2.726v.044M17.857.714H2.143A1.429 1.429 0 0 0 .714 2.143v12.143a1.429 1.429 0 0 0 1.429 1.428H5v3.572l4.457-3.572h8.4a1.429 1.429 0 0 0 1.429-1.428V2.143A1.429 1.429 0 0 0 17.857.714Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

HelpChat20Icon.displayName = 'HelpChat20Icon';
