/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Products20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M19.286.714h-3.572l-1.243 12.357a1.429 1.429 0 0 1-1.428 1.215h-9a1.429 1.429 0 0 1-1.429-.972L.714 7.6a1.429 1.429 0 0 1 .2-1.286 1.429 1.429 0 0 1 1.229-.6h13.071M5 18.571a.714.714 0 1 1-1.429 0 .714.714 0 0 1 1.429 0Zm9.286 0a.714.714 0 1 1-1.429 0 .714.714 0 0 1 1.429 0Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Products20Icon.displayName = 'Products20Icon';
