/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const DragNDrop20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill={stroke}
      viewBox="0 0 20 20"
    >
      <path
        fill={stroke}
        d="M6.412 4.824a1.412 1.412 0 1 0 0-2.824 1.412 1.412 0 0 0 0 2.824Zm0 6.588a1.412 1.412 0 1 0 0-2.824 1.412 1.412 0 0 0 0 2.824ZM13 4.824A1.412 1.412 0 1 0 13 2a1.412 1.412 0 0 0 0 2.824Zm0 6.588a1.412 1.412 0 1 0 0-2.824 1.412 1.412 0 0 0 0 2.824ZM13 18a1.412 1.412 0 1 0 0-2.823A1.412 1.412 0 0 0 13 18Zm-6.588 0a1.412 1.412 0 1 0 0-2.824 1.412 1.412 0 0 0 0 2.824Z"
      />
    </svg>
  );
};

DragNDrop20Icon.displayName = 'DragNDrop20Icon';
