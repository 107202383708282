/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Support16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.286 6.286A1.714 1.714 0 1 1 8 8v1.143M15.428 8A7.429 7.429 0 1 1 .571 8a7.429 7.429 0 0 1 14.857 0ZM8 12a.286.286 0 1 1 0-.571A.286.286 0 0 1 8 12Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Support16Icon.displayName = 'Support16Icon';
