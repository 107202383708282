/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const DragNDrop12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill={stroke}
      viewBox="0 0 12 12"
    >
      <path
        fill={stroke}
        d="M3.9 2.765c.497 0 .9-.395.9-.883A.891.891 0 0 0 3.9 1c-.497 0-.9.395-.9.882 0 .488.403.883.9.883Zm0 4.117c.497 0 .9-.395.9-.882a.891.891 0 0 0-.9-.882c-.497 0-.9.395-.9.882s.403.882.9.882Zm4.2-4.117c.497 0 .9-.395.9-.883A.891.891 0 0 0 8.1 1c-.497 0-.9.395-.9.882 0 .488.403.883.9.883Zm0 4.117c.497 0 .9-.395.9-.882a.891.891 0 0 0-.9-.882c-.497 0-.9.395-.9.882s.403.882.9.882ZM8.1 11c.497 0 .9-.395.9-.882a.891.891 0 0 0-.9-.883c-.497 0-.9.395-.9.883 0 .487.403.882.9.882Zm-4.2 0c.497 0 .9-.395.9-.882a.891.891 0 0 0-.9-.883c-.497 0-.9.395-.9.883 0 .487.403.882.9.882Z"
      />
    </svg>
  );
};

DragNDrop12Icon.displayName = 'DragNDrop12Icon';
