/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Applications20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M6.429.714H2.143c-.79 0-1.429.64-1.429 1.429v4.286c0 .789.64 1.428 1.429 1.428h4.286c.788 0 1.428-.64 1.428-1.428V2.143A1.43 1.43 0 0 0 6.429.714Zm11.428 0h-4.285c-.79 0-1.429.64-1.429 1.429v4.286c0 .789.64 1.428 1.429 1.428h4.285c.79 0 1.429-.64 1.429-1.428V2.143A1.43 1.43 0 0 0 17.857.714ZM6.429 12.143H2.143c-.79 0-1.429.64-1.429 1.429v4.285c0 .79.64 1.429 1.429 1.429h4.286a1.43 1.43 0 0 0 1.428-1.429v-4.285c0-.79-.64-1.429-1.428-1.429Zm11.428 0h-4.285c-.79 0-1.429.64-1.429 1.428v4.286c0 .79.64 1.429 1.429 1.429h4.285c.79 0 1.429-.64 1.429-1.429v-4.285c0-.79-.64-1.43-1.429-1.43Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Applications20Icon.displayName = 'Applications20Icon';
