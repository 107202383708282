/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const TurnBackward16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 1.714.571 5.143m0 0L4 8.57M.571 5.143h10.286a4.571 4.571 0 0 1 0 9.143H5.143"
      />
    </svg>
  );
};

TurnBackward16Icon.displayName = 'TurnBackward16Icon';
