/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Archive16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.714 6.331h12.572M1.714 2.903h12.572m1.143 8a1.143 1.143 0 0 0-1.143-1.143h-3.429v1.143H5.143V9.76H1.714a1.143 1.143 0 0 0-1.143 1.143v3.428a1.143 1.143 0 0 0 1.143 1.143h12.572a1.143 1.143 0 0 0 1.143-1.143v-3.428Z"
      />
    </svg>
  );
};

Archive16Icon.displayName = 'Archive16Icon';
