/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Alphabet20Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M9.286 17.857 13.77 7.386a.557.557 0 0 1 1.029 0l4.486 10.471m-7.986-4.7h5.972m-16.558 4.7L7.243 2.63a.814.814 0 0 1 1.428 0l1.329 3m-6.357 5.4h3.5"
      />
    </svg>
  );
};

Alphabet20Icon.displayName = 'Alphabet20Icon';
