/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Indicators12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="m1.063 5.606 9.857-4.483m0 0L9.077.429m1.843.694-.686 1.843m1.123 8.614H9.214v-6a.429.429 0 0 1 .429-.429h1.286a.429.429 0 0 1 .428.429v6Zm-4.286 0H4.93V6.866a.429.429 0 0 1 .428-.429h1.286a.429.429 0 0 1 .428.429v4.714Zm-4.285 0H.643V8.151a.429.429 0 0 1 .428-.428h1.286a.429.429 0 0 1 .429.428v3.429Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Indicators12Icon.displayName = 'Indicators12Icon';
