/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Notifications16Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.286 14.091a1.772 1.772 0 0 0 3.428 0M8 .571a4.903 4.903 0 0 1 4.903 4.903c0 5.452 1.988 6.526 2.525 6.526H.571c.549 0 2.526-1.086 2.526-6.526A4.903 4.903 0 0 1 8 .571Z"
      />

      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

Notifications16Icon.displayName = 'Notifications16Icon';
