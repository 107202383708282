/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Print12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M11.714 9.143a.857.857 0 0 0-.857-.857H3.143a.857.857 0 0 0-.857.857m9.428 0v3.428H2.286V9.143m9.428 0a.857.857 0 0 0 .858-.857V6.57a.857.857 0 0 0-.858-.857H2.286a.857.857 0 0 0-.857.857v1.715a.857.857 0 0 0 .857.857m2.143 1.286h5.143m-6-9h6.857v4.285H3.57V1.43Z"
      />
    </svg>
  );
};

Print12Icon.displayName = 'Print12Icon';
