/**
  THIS FILE IS AUTOGENERATED
*/

import React, { FC } from 'react';
import { SingleIconProps } from '../types';

export const Retail12Icon: FC<SingleIconProps> = ({
  stroke = 'currentColor',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
        d="M2.286 8.286v3.857a.428.428 0 0 0 .428.428h8.572a.429.429 0 0 0 .428-.428V8.286M1.428 4v1.714a.429.429 0 0 0 .429.429h10.286a.429.429 0 0 0 .428-.429V4M1.428 4l1.046-2.1a.857.857 0 0 1 .772-.471h7.508a.857.857 0 0 1 .772.471L12.57 4M1.428 4h11.143M7.857 8.286v4.286"
      />
    </svg>
  );
};

Retail12Icon.displayName = 'Retail12Icon';
